import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

interface NewCategory {
  _id: string;
  title: string;
  expressions?: string;
  satisfactionLevel?: number;
  sub_category?: SubCategory[];
  questions?: Question[];
}

interface SubCategory {
  _id: string;
  title: string;
  expressions?: string;
  satisfactionLevel?: number;
  parentId?: string;
  questions?: Question[];
}

interface Question {
  _id: string;
  content: string;
  expressions?: string;
  satisfactionLevel?: number;
  categoryId?: string;
  parentCategoryId?: string;
}

@Component({
  selector: 'view-answers',
  templateUrl: './published-survey.component.html',
  styleUrls: ['./published-survey.component.css'],
  standalone: false,
})
export class PublishedServeyComponent implements OnInit {
  currentUserId: string;
  notificationMessage: string;
  newcategories: NewCategory[];
  surveyId: string;
  SurveyTitle: { title: string; expressions?: string } = { title: '' };
  viewComment: any[];
  sendData: any;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.notificationMessage = '';
    this.newcategories = [];
    this.viewComment = [];
    this.sendData = {};
  }

  // Helper method to safely handle expressions
  getExpressionsOrDefault(expressions?: string): string {
    return expressions || 'assets/default-emoji.png';
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['surveyId'] !== undefined) {
        this.surveyId = params['surveyId'];
        this.getSurveyDetails();
        this.loadSurvey();
      }
    });
  }

  getSurveyDetails(): void {
    const data = { userId: this.currentUserId, serveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getViewServeyDetails(data).subscribe(
      (data) => {
        if (data) {
          this.SurveyTitle = {
            title: data.data.title || '',
            expressions: data.data.expressions || undefined,
          };
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  loadSurvey(): void {
    const data = { userId: this.currentUserId, serveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getPublishedServeyDetails(data).subscribe(
      (data) => {
        if (data.Questions.length > 0) {
          this.newcategories = data.Questions;
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  openModel(opt: string, cmntData: any): void {
    this.sendData = {};
    if (opt === 'catComment') {
      this.sendData = {
        surveyId: this.surveyId,
        parentCategoryId: '',
        categoryId: cmntData._id,
        model: 'surveyCategoryResponse',
      };
    } else if (opt === 'catQuestionComment') {
      this.sendData = {
        surveyId: this.surveyId,
        parentCategoryId: cmntData.categoryId,
        categoryId: 'none',
        questionId: cmntData._id,
        model: 'surveyResponse',
      };
    } else if (opt === 'subCatComment') {
      this.sendData = {
        surveyId: this.surveyId,
        parentCategoryId: cmntData.parentId,
        categoryId: cmntData._id,
        model: 'surveyCategoryResponse',
      };
    } else if (opt === 'subCatQuestionComment') {
      this.sendData = {
        surveyId: this.surveyId,
        parentCategoryId: cmntData.parentCategoryId,
        categoryId: cmntData.categoryId,
        questionId: cmntData._id,
        model: 'surveyResponse',
      };
    }
    this.notificationMessage = 'Loading...';
    this.viewComment = [];
    this.surveyService.viewPublishedComment(this.sendData).subscribe(
      (data) => {
        $('#publishedModel').modal('show');
        if (data.viewData.length > 0) {
          this.viewComment = data.viewData.filter(
            (element: any) => element.comments
          );
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }
}
