import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SurveyService } from './../services/survey.service';
import { UserService } from './../services/user.service';

declare var $: any;

@Component({
  selector: 'response-analysis',
  templateUrl: './response-analysis.component.html',
  styleUrls: ['./response-analysis.component.css'],
  standalone: false,
})
export class ResponseAnalysisComponent implements OnInit {
  word_list: any[] = [];
  survey_data: any[] = [];
  survey_comments: any[] = [];
  surveyId: string = '';
  quesId: string = '';
  currentUserId: string;
  uname: string = '';
  dname: string = '';
  oname: string = '';
  depFilter: boolean = true;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
  }

  // Add this method to support the mat-slider in the template
  formatLabel(value: number): string {
    return `${value}`;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['surveyId'] !== undefined) {
        this.surveyId = params['surveyId'];
        this.quesId = params['quesId'];
        this.getWordCloud();
        this.getSurveyAvgData();
        this.getUserDetails();
        this.getDepFilter();
      }
    });
  }

  getWordCloud(): void {
    const data = { surveyId: this.surveyId, queId: this.quesId };
    this.surveyService.getCloudData(data).subscribe({
      next: (data) => {
        if (data.data.length > 0) {
          this.word_list = data.data;
          $('#my_favorite_latin_words').jQCloud(this.word_list);
        } else {
          this.word_list = [];
        }
      },
      error: (error) => {
        this.word_list = [];
        console.error('Error fetching word cloud data:', error);
      },
    });
  }

  getSurveyAvgData(): void {
    const data = {
      surveyId: this.surveyId,
      userId: this.currentUserId,
      queId: this.quesId,
    };
    this.surveyService.getSurveyD(data).subscribe({
      next: (data) => {
        if (data.data.length > 0) {
          this.survey_data = data.data;
          setTimeout(() => {
            $('.mat-slider-thumb-container').css('display', 'none');
            $('.mat-slider-track-background').css('transform', 'none');
            $('.mat-slider-track-background').css('-webkit-transform', 'none');
            $('.mat-slider-track-background').css('-moz-transform', 'none');
          }, 200);
        } else {
          this.survey_data = [];
        }
      },
      error: (error) => {
        this.survey_data = [];
        console.error('Error fetching survey data:', error);
      },
    });
  }

  getUserDetails(): void {
    const data = { userId: this.currentUserId };
    this.surveyService.getUserDetails(data).subscribe({
      next: (data) => {
        if (data) {
          this.uname = data.name;
          this.dname = data.dname;
          this.oname = data.oname;
        } else {
          this.uname = 'Yours';
          this.dname = 'Department';
          this.oname = 'Organization';
        }
      },
      error: (error) => {
        this.uname = 'Yours';
        this.dname = 'Department';
        this.oname = 'Organization';
        console.error('Error fetching user details:', error);
      },
    });
  }

  getComments(): void {
    const data = { surveyId: this.surveyId, queId: this.quesId };
    this.surveyService.getComments(data).subscribe({
      next: (data) => {
        if (data.data.length > 0) {
          this.survey_comments = data.data;
          $('#commentModel').modal('show');
        } else {
          this.survey_comments = [];
        }
      },
      error: (error) => {
        this.survey_comments = [];
        console.error('Error fetching comments:', error);
      },
    });
  }

  backtometric(): void {
    this.router.navigate(['/metriccomparison', this.surveyId]);
  }

  getDepFilter(): void {
    const data = { userId: this.currentUserId, surveyId: this.surveyId };
    this.surveyService.getDepFilter(data).subscribe({
      next: (data) => {
        if (data) {
          this.depFilter = data.data;
        } else {
          this.depFilter = true;
        }
      },
      error: (error) => {
        this.depFilter = true;
        console.error('Error fetching department filter:', error);
      },
    });
  }
}
