import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';
import { Survey } from './../types/survey';
import { newCategory } from './../types/newcategory';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'view-answers',
  templateUrl: './metric-comparison.component.html',
  styleUrls: ['./metric-comparison.component.css'],
  standalone: false,
})
export class MetricComparisonComponent implements OnInit {
  currentUserId: string;
  notificationMessage: string;
  newcategories: any[]; // Changed from newCategory[] to any[] to handle custom properties
  surveyId: string;
  SId: string;
  SurveyTitle: any;
  PRData: any;
  surStatus: any;
  uname: any;
  dname: any;
  oname: any;
  depFilter: any;
  catArr: any;
  hc: any;
  sc: any;
  pArr: any;
  mArr: any;
  snackBarRef: any;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.SId = '';
    this.notificationMessage = '';
    this.newcategories = [];
    this.SurveyTitle = {};
    this.PRData = {};
    this.surStatus = '';
    this.uname = '';
    this.dname = '';
    this.oname = '';
    this.depFilter = true;
    this.catArr = [];
    this.hc = [];
    this.sc = [];
    this.pArr = [];
    this.mArr = [];
    this.snackBarRef = this.snackBar.open('Loading Data...');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['surveyId'] !== undefined) {
        this.surveyId = params['surveyId'];
        this.getSurveyDetails();
        this.getUserDetails();
        this.loadSurvey();
        this.getDepFilter();
      }
    });

    $(window).scroll(() => {
      if ($(this).scrollTop()) {
        $('#toTop').fadeIn();
      } else {
        $('#toTop').fadeOut();
      }
    });

    $('#toTop').click(function () {
      $('html, body').animate({ scrollTop: 0 }, 1000);
    });
  }

  public showImportance(e: Event) {
    var cls = $('.simpcl').attr('class');
    var fcls = cls.split(' ');
    var chkClass = fcls[1];

    if (chkClass == 'chk') {
      $('.simpcl').removeClass('chk');
      $('.simpcl').addClass('uchk');
      $('.simpc').css('display', 'none');
      $('.cs2').attr('colspan', 1);
      $('.cs2emo').css('text-align', 'center');
    } else {
      $('.simpcl').removeClass('uchk');
      $('.simpcl').addClass('chk');
      $('.simpc').css('display', 'block');
      $('.cs2').attr('colspan', 2);
      $('.cs2emo').css('text-align', 'left');
    }
  }

  getSurveyDetails(): void {
    var data = { userId: this.currentUserId, serveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getViewServeyDetails(data).subscribe(
      (data) => {
        if (data) {
          this.SId = this.surveyId;
          this.SurveyTitle = data.data;
          this.surStatus = data.data.serveyStatus;
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  getUserDetails(): void {
    var data = { userId: this.currentUserId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getUserDetails(data).subscribe(
      (data) => {
        if (data) {
          this.uname = data.name;
          this.dname = data.dname;
          this.oname = data.oname;
        } else {
          this.uname = 'Yours';
          this.dname = 'Department';
          this.oname = 'Organization';
        }
      },
      (error) => {
        this.uname = 'Yours';
        this.dname = 'Department';
        this.oname = 'Organization';
      }
    );
  }

  // Updated to make the event parameter optional
  public cALL(e?: Event) {
    this.hc = [];
    this.sc = [];
    console.log(this.catArr);
    this.catArr.forEach((data: any, index: number) => {
      var cl = $('#' + data).attr('class');
      if (cl == 'plusbtn') {
        this.hc.push(data);
      } else {
        this.sc.push(data);
      }
    });

    console.log(this.hc);
    console.log(this.sc);

    var idc = $('#colAll').attr('class');
    if (idc == 'plusbtn') {
      this.hc.forEach((data: any, index: number) => {
        this.show_hide_row(data);
      });
    } else {
      this.sc.forEach((data: any, index: number) => {
        this.show_hide_row(data);
      });
    }
  }

  public show_hide_row(row: any) {
    this.pArr = [];
    this.mArr = [];

    var cls = $('.' + row).attr('class');
    var fcls = cls.split(' ');
    var chkClass = '';

    if (fcls[3] == undefined) {
      if (fcls[1] == 'ng-star-inserted') {
        chkClass = fcls[2];
      } else {
        chkClass = fcls[1];
      }
    } else {
      if (fcls[2] == 'ng-star-inserted') {
        chkClass = fcls[3];
      } else {
        chkClass = fcls[2];
      }
    }

    if (chkClass == 'show1') {
      $('.' + row).removeClass('show1');
      $('.' + row).addClass('hide');
      $('.hide').css('display', 'none');
    } else {
      $('.' + row).removeClass('hide');
      $('.' + row).addClass('show1');
      $('.show1').css('display', 'table-row');
    }

    var idc = $('#' + row).attr('class');
    if (idc == 'plusbtn') {
      $('#' + row).removeClass('plusbtn');
      $('#' + row).addClass('minusbtn');
    } else {
      $('#' + row).removeClass('minusbtn');
      $('#' + row).addClass('plusbtn');
    }

    this.catArr.forEach((data: any, index: number) => {
      var cl = $('#' + data).attr('class');
      if (cl == 'plusbtn') {
        this.pArr.push(cl);
      } else {
        this.mArr.push(cl);
      }
    });

    if (this.catArr.length == this.pArr.length) {
      $('#colAll').removeClass('minusbtn');
      $('#colAll').addClass('plusbtn');
    }

    if (this.catArr.length == this.mArr.length) {
      $('#colAll').removeClass('plusbtn');
      $('#colAll').addClass('minusbtn');
    }
  }

  loadSurvey(): void {
    var data = { userId: this.currentUserId, surveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getMetricData(data).subscribe(
      (data) => {
        this.snackBarRef.dismiss();
        if (data.Questions != null) {
          if (data.Questions.length > 0) {
            data.Questions.forEach((obj: any, index: number) => {
              if (obj.mct) {
                obj.mct.forEach((obj1: any, index: number) => {
                  this.catArr.push(obj1._id);
                });
              }
            });

            this.newcategories = data.Questions;
            setTimeout(function () {
              $('.mat-slider-thumb-container').css('display', 'none');
              $('.mat-slider-track-background').css('transform', 'none');
              $('.mat-slider-track-background').css(
                '-webkit-transform',
                'none'
              );
              $('.mat-slider-track-background').css('-moz-transform', 'none');
            }, 500);
          } else {
            this.notificationMessage = 'No survey data found in the system.';
          }
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.snackBarRef.dismiss();
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  getDepFilter(): void {
    var data = { userId: this.currentUserId, surveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getDepFilter(data).subscribe(
      (data) => {
        if (data) {
          this.depFilter = data.data;
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  responseanalysis(surveyId: string, quesId: string): void {
    this.router.navigate(['/responseanalysis', surveyId, quesId]);
  }

  responseanalysisCat(surveyId: string, catId: string): void {
    this.router.navigate(['/responseanalysiscat', surveyId, catId]);
  }

  responseanalysisSurvey(surveyId: string): void {
    this.router.navigate(['/responseanalysissurvey', surveyId]);
  }
}
