<div class="front about container-fluid">
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="2"
      aria-valuemin="1"
      aria-valuemax="100"
      style="width: 20%"
      data-toggle="tooltip"
      data-placement="bottom"
      title="X of X answered"
      id="progress-tip"
    ></div>
  </div>
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <form
                (ngSubmit)="save()"
                #userProfileForm="ngForm"
                id="userProfileForm"
              >
                <div class="panel-body">
                  <h2 class="about-head">Tell us a little about yourself</h2>
                  <!-- <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Email</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <label class="form-control" id="email">{{userProfile.email}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Department</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <label class="form-control" id="email">{{userProfile.departmentName}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Subdepartment</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <label class="form-control" id="email">{{userProfile.subdepartmentName}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Name</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="fullname" [(ngModel)]="userProfile.fullname" #fullname="ngModel"  id="fullname" required>
                                                    <div *ngIf="fullname.invalid && (fullname.dirty || fullname.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="fullname.errors?.['required']">
                                                            Name is required.
                                                        </ng-container>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Phone</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="phone" [(ngModel)]="userProfile.phone" #phone="ngModel"  id="phone" required>
                                                    <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="phone.errors?.['required']">
                                                            Phone is required.
                                                        </ng-container>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">Address</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="address" [(ngModel)]="userProfile.address" #address="ngModel"  id="address" required>
                                                    <div *ngIf="address.invalid && (address.dirty || address.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="address.errors?.['required']">
                                                            Address is required.
                                                        </ng-container>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">State</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <select name="state" [(ngModel)]="userProfile.state"  #state="ngModel" required class="form-control">
                                                        <option value="" selected>- Select -</option>
                                                        <ng-container *ngFor="let st of states">
                                                            <option value="{{st._id}}">{{st.name}}</option>
                                                        </ng-container>
                                                    </select>
                                                    <div *ngIf="state.invalid && (state.dirty || state.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="state.errors?.['required']">
                                                            State is required.
                                                        </ng-container>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label label1">City</label>
                                            <div class="col-md-5 col-sm-7">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="city" [(ngModel)]="userProfile.city" #city="ngModel"  id="address" required>
                                                    <div *ngIf="city.invalid && (city.dirty || city.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="city.errors?.['required']">
                                                            City is required.
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                  <div class="row">
                    <div class="form-group mb">
                      <label class="col-sm-4 control-label mb"
                        >In what year were you born?
                        <span style="color: red">*</span>
                      </label>

                      <div class="col-md-5 col-sm-7">
                        <div class="form-group fdatepicker">
                          <select
                            name="dob"
                            [(ngModel)]="userProfile.dob"
                            #dob="ngModel"
                            required
                            class="form-control"
                          >
                            <option value="" selected>- Select -</option>

                            <ng-container *ngFor="let year of yearOptions">
                              <option value="{{ year }}">{{ year }}</option>
                            </ng-container>
                          </select>
                          <!-- <ng-container>
                                                    <input matInput [matDatepicker]="picker" placeholder="Choose a date" name="dob" class="form-control" [(ngModel)]="userProfile.dob" #dob="ngModel" required>

                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>

                                                    <mat-datepicker #picker></mat-datepicker>
                                                    </ng-container> -->
                          <div
                            *ngIf="dob.invalid && (dob.dirty || dob.touched)"
                            class="alert alert-danger"
                          >
                            <ng-container *ngIf="dob.errors?.['required']">
                              Birth date is required.
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--                                    <div class="row">
                                        <div class="form-group">
                                            <label class="col-sm-4 control-label">What is your gender?</label>

                                            <div class="col-sm-7">
                                                <div class="form-group no-spacing">
                                                    <div class="col-sm-3 col-xs-6" align="center">
                                                        <label class="genderImage">
                                                            <img src="{{maleImgSrc}}" class="img-check" (click)="setGender('male')">
                                                        </label>
                                                        <p class="radio1">Male</p>
                                                    </div>
                                                    <div class="col-sm-3 col-xs-6" align="center">
                                                        <label class="genderImage">
                                                            <img src="{{femaleImgSrc}}" class="img-check" (click)="setGender('female')">
                                                        </label>
                                                        <p class="radio1">Female</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>-->

                  <div class="row">
                    <div class="form-group">
                      <label class="col-sm-4 control-label label1"
                        >What is your education level?</label
                      >
                      <div class="col-md-5 col-sm-7">
                        <div class="form-group">
                          <select
                            name="educationLevel"
                            [(ngModel)]="userProfile.educationLevel"
                            #educationLevel="ngModel"
                            class="form-control"
                          >
                            <option value="" selected>- Select -</option>

                            <ng-container
                              *ngFor="
                                let educationLevel of educationLevelOptions
                              "
                            >
                              <option value="{{ educationLevel }}">
                                {{ educationLevel }}
                              </option>
                            </ng-container>
                          </select>

                          <!-- <div *ngIf="educationLevel.invalid && (educationLevel.dirty || educationLevel.touched)" class="alert alert-danger">
                                                        <ng-container *ngIf="educationLevel.errors?.['required']">
                                                            Education level is required.
                                                        </ng-container>
                                                    </div> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group">
                      <label class="col-sm-4 control-label label1"
                        >What is your job level?</label
                      >
                      <div class="col-md-5 col-sm-7">
                        <div class="form-group">
                          <select
                            name="jobLevel"
                            [(ngModel)]="userProfile.jobLevel"
                            #jobLevel="ngModel"
                            class="form-control"
                          >
                            <option value="" selected>- Select -</option>

                            <ng-container
                              *ngFor="let jobLevel of jobLevelOptions"
                            >
                              <option value="{{ jobLevel }}">
                                {{ jobLevel }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="form-group">
                      <div class="col-sm-6 col-sm-offset-6" align="right">
                        <button
                          type="button"
                          class="btn btn-sm btn-secondary"
                          (click)="skip()"
                        >
                          Skip
                        </button>

                        <button
                          type="submit"
                          class="btn btn-sm btn-primary btn-login"
                          [disabled]="!userProfileForm.form.valid"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- END panel-->
        </div>
      </div>
    </div>
    <!-- END row-->
  </section>
</div>
