import { Component } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  standalone: false,
})
export class PageNotFoundComponent {
  title: string = '404 Not Found';
}
