export class ResetPassword {
    
    public password : string; 
    public confPassword : string;
    public token : string; 
       
    constructor() {
        this.password = '';
        this.confPassword = '';
        this.token = '';
    }

    reset(): void {        
        this.password = '';
        this.confPassword = '';
        this.token = '';
    }
}