import { Component, OnInit, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../services/user.service';
import { DOCUMENT } from '@angular/common';
import { SignIn } from '../signin-signup/signin';
import { ForgotPassword as ForgotPasswordModel } from '../signin-signup/forgot-password';

// Define a simplified interface for the component's use
interface SignUp {
  email: string;
  password: string;
  // Add other required fields
}

// Local interface that includes the reset method
interface ForgotPassword {
  email: string;
  reset(): void;
}

@Component({
  selector: 'app-signin-signup',
  templateUrl: './signin-signup.component.html',
  styleUrls: ['./signin-signup.component.css'],
  standalone: false,
})
export class SignInSignUpComponent implements OnInit {
  // Initialize with proper types
  signinModel: SignIn = new SignIn();
  signupModel: SignUp = {
    email: '',
    password: '',
  };
  forgotPasswordModel: ForgotPasswordModel = new ForgotPasswordModel();

  signUpSubmitted = false;
  currentUserId: string | null = null;
  private document = inject(DOCUMENT);

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // Check for query parameters
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    if (queryParams.has('msg')) {
      this.snackBar.open(queryParams.get('msg') || '', 'Close', {
        duration: 5000,
      });
    }

    // Check for route parameters
    this.activatedRoute.paramMap.subscribe((params) => {
      const token = params.get('token');
      if (token) {
        this.verifyEmail(token);
      }
    });

    // Check current user
    this.currentUserId = this.userService.getCurrentUserId();
    if (this.currentUserId) {
      this.router.navigate(['/']);
    }
  }

  doLogin1(): void {
    alert('working');
  }

  doLogin(): void {
    this.userService.signIn(this.signinModel).subscribe({
      next: (data: any) => {
        if (data['error'] === 0 && data != null) {
          this.userService.setUserLoggedIn(data['data']);
          this.router.navigate(['/']);
        } else {
          this.snackBar.open(data['message'] || 'Login failed', 'Close', {
            duration: 5000,
          });
        }
      },
      error: (error: any) => {
        console.error('Failed to sign in:', error);
        this.snackBar.open(
          'Failed to sign in. Something went wrong.',
          'Close',
          {
            duration: 5000,
          }
        );
      },
    });
  }

  verifyEmail(token: string): void {
    this.userService.verifyEmail(token).subscribe({
      next: (data: any) => {
        if (data['data'] === 'success') {
          this.snackBar.open(
            data['message'] || 'Email verified successfully',
            'Close',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackBar.open(
            'Failed to verify your email address. Please try again.',
            'Close',
            {
              duration: 5000,
            }
          );
        }
      },
      error: (error: any) => {
        this.snackBar.open(
          error['error']?.message || 'Email verification failed',
          'Close',
          {
            duration: 5000,
          }
        );
      },
    });
  }

  forgotPassword(): void {
    this.userService.forgotPassword(this.forgotPasswordModel).subscribe({
      next: (data: any) => {
        // Reset model
        this.forgotPasswordModel.reset();

        // Close modal (using Bootstrap 3)
        this.closeModal('#forgot');

        if (data['data'] === 'success') {
          this.snackBar.open(
            data['message'] || 'Password reset link sent',
            'Close',
            {
              duration: 5000,
            }
          );
        } else {
          this.snackBar.open(
            'Failed to process this request. Please try again.',
            'Close',
            {
              duration: 5000,
            }
          );
        }
      },
      error: (error: any) => {
        this.snackBar.open(
          error['error']?.message || 'Failed to process request',
          'Close',
          {
            duration: 5000,
          }
        );
      },
    });
  }

  // Updated utility method to close modal - adapted for Bootstrap 3
  private closeModal(selector: string): void {
    try {
      // jQuery approach for Bootstrap 3
      const $ = (window as any).$;
      if ($) {
        $(selector).modal('hide');
      }
    } catch (error) {
      console.error('Error closing modal:', error);
    }
  }
}
