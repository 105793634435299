import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';
import { Survey } from './../types/survey';
import { newCategory } from './../types/newcategory';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'view-answers',
  templateUrl: './view-answer.component.html',
  styleUrls: ['./view-answer.component.css'],
  standalone: false,
})
export class ViewAnswerComponent implements OnInit {
  currentUserId: string;
  notificationMessage: string;
  newcategories: newCategory[];
  surveyId: string;
  SurveyTitle: any;
  PRData: any;
  surStatus: any;
  viewVal: any;
  snackBarRef: any;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.notificationMessage = '';
    this.newcategories = [];
    this.SurveyTitle = {};
    this.PRData = {};
    this.surStatus = '';
    this.viewVal = 'own';
    this.snackBarRef = this.snackBar.open('Loading Data...');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['surveyId'] !== undefined) {
        this.surveyId = params['surveyId'];
        this.getSurveyDetails();
        this.loadSurvey();
      }
    });
  }

  public onOptionSelected(event: any) {
    if (event == 'own') {
      this.viewVal = 'own';
    } else if (event == 'org') {
      this.viewVal = 'org';
    } else if (event == 'dep') {
      this.viewVal = 'dep';
    }
    this.loadSurvey();
  }

  getSurveyDetails(): void {
    var data = { userId: this.currentUserId, serveyId: this.surveyId };
    this.notificationMessage = 'Loading...';
    this.surveyService.getViewServeyDetails(data).subscribe(
      (data) => {
        if (data) {
          this.SurveyTitle = data.data;
          this.surStatus = data.data.serveyStatus;
        } else {
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }
  loadSurvey(): void {
    var data = {
      userId: this.currentUserId,
      serveyId: this.surveyId,
      filter: this.viewVal,
    };
    this.notificationMessage = 'Loading...';
    if (this.viewVal == 'own') {
      this.surveyService.getViewAnswer(data).subscribe(
        (data) => {
          this.snackBarRef.dismiss();
          if (data.Questions.length > 0) {
            this.newcategories = data.Questions;
          } else {
            this.notificationMessage = 'No survey data found in the system.';
          }
        },
        (error) => {
          this.snackBarRef.dismiss();
          this.notificationMessage = 'Failed to load survey data.';
        }
      );
    } else {
      this.surveyService.getPublishedServeyDetails(data).subscribe(
        (data) => {
          this.snackBarRef.dismiss();
          if (data.Questions != null) {
            if (data.Questions.length > 0) {
              this.newcategories = data.Questions;
            } else {
              this.notificationMessage = 'No survey data found in the system.';
            }
          } else {
            this.newcategories = data.Questions;
            this.notificationMessage = 'Not enough user for department view.';
          }
        },
        (error) => {
          this.snackBarRef.dismiss();
          this.notificationMessage = 'Failed to load survey data.';
        }
      );
    }
  }
  openModel(opt: any, cmntData: any): void {
    $('#publishedModel').modal('show');
    this.PRData = {};
    this.PRData.comments = cmntData.comments;
    this.PRData.managerComment = cmntData.managerComment;
  }
}
