import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseEmoji',
  standalone: false,
})
export class ParseEmojiPipe implements PipeTransform {
  transform(expressions: number | string): string {
    let imagepath = 'assets/imgs/emoji_new/';

    // Handle string input by first checking if it's one of the direct emoji codes
    if (typeof expressions === 'string') {
      // Check if it's already a direct emoji code (emo1, emo2, etc.)
      if (expressions.startsWith('emo')) {
        return imagepath + expressions + '.png';
      }

      // If it's not an emoji code, try to convert it to a number
      expressions = parseFloat(expressions) || 0;
    }

    // Now handle it as a number
    if (expressions > 93.75) {
      return imagepath + 'emo10.png';
    } else if (expressions > 81.25 && expressions <= 93.75) {
      return imagepath + 'emo9.png';
    } else if (expressions > 68.75 && expressions <= 81.25) {
      return imagepath + 'emo8.png';
    } else if (expressions > 56.25 && expressions <= 68.75) {
      return imagepath + 'emo7.png';
    } else if (expressions > 42.75 && expressions <= 56.25) {
      return imagepath + 'emo5.png';
    } else if (expressions > 31.25 && expressions <= 42.75) {
      return imagepath + 'emo4.png';
    } else if (expressions > 18.75 && expressions <= 31.25) {
      return imagepath + 'emo3.png';
    } else if (expressions > 6.25 && expressions <= 18.75) {
      return imagepath + 'emo2.png';
    } else if (expressions > 0.1 && expressions <= 6.25) {
      return imagepath + 'emo1.png';
    } else if (expressions == 0) {
      return imagepath + 'emo0.png';
    } else {
      return imagepath + 'emo0.png';
    }
  }
}
