<div class="front priorities container-fluid">
  <div
    class="progress"
    data-toggle="tooltip"
    data-placement="bottom"
    title="X of X answered"
  >
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="2"
      aria-valuemin="1"
      aria-valuemax="100"
      style="width: 20%"
    ></div>
  </div>
  <!-- Main section-->
  <section class="first-question">
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body">
                <ng-container *ngIf="contentAvailable">
                  <div class="row">
                    <div class="form-group">
                      <div class="col-sm-12" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav nw-nav-mb">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>
                            <li>
                              <a [routerLink]="['/survey', surveyId]">
                                <img
                                  src="assets/imgs/category.png"
                                  title="Back to Main Categories"
                                  alt="Back to Main Categories"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                [routerLink]="['/survey', surveyId]"
                                style="color: #656565; text-decoration: none"
                              >
                                <img
                                  src="assets/imgs/icon-9000.png"
                                  title="Level Up"
                                  alt="Level Up"
                                  style="margin-bottom: 10px"
                              /></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-container>
                    <div class="title-bar-row2">
                      <h2 class="about-head scTitle">
                        <a [routerLink]="['/survey', surveyId]">
                          <img
                            src="assets/imgs/category.png"
                            title="Back to Main Categories"
                            alt="Back to Main Categories"
                          />
                        </a>
                        <a
                          [routerLink]="['/survey', surveyId]"
                          style="color: #656565; text-decoration: none"
                        >
                          <img
                            src="assets/imgs/icon-9000.png"
                            title="Level Up"
                            alt="Level Up"
                            style="margin-bottom: 10px"
                          />
                          <span class="title-tx">{{ categoryTitle }} </span>
                        </a>
                        <!-- <a [routerLink]="['/survey', surveyId ]">
                                       </a> -->
                      </h2>
                    </div>
                  </ng-container>

                  <!-- Changes Start Mitul-->
                  <div class="row row-priorities nnw-row">
                    <div
                      class="col-sm-12 text-right pad-right0 top-new-prt-add"
                      *ngFor="let mc of mainCategory"
                    >
                      <span
                        class="absVal text-right"
                        style="position: static; font-size: 16px"
                        *ngIf="isValues === true"
                        >{{ mc.expressions }}</span
                      >
                      <ng-container *ngIf="mc.expressions !== ''">
                        <img
                          [src]="mc.expressions | parseEmoji"
                          style="max-width: 35px; vertical-align: top"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          alt="Satisfaction emoji"
                        />
                      </ng-container>
                      <ng-container *ngIf="mc.expressions === ''">
                        <img
                          src="assets/imgs/emoji_new/emo0.png"
                          style="max-width: 35px; vertical-align: top"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          alt="Default emoji"
                        />
                      </ng-container>
                      <div class="submission question-submission sc-em-nw">
                        <a href="javascript:void(0)" class="comment">
                          <ng-container *ngIf="mc.comments === undefined">
                            <img
                              class="commentimg"
                              src="assets/imgs/comment.png"
                              style="width: 36%"
                              alt="Comment"
                            />
                          </ng-container>
                          <ng-container *ngIf="mc.comments !== undefined">
                            <ng-container *ngIf="mc.comments === ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/comment.png"
                                style="width: 36%"
                                alt="Comment"
                              />
                            </ng-container>
                            <ng-container *ngIf="mc.comments !== ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/chat.png"
                                style="width: 36%"
                                alt="Chat"
                              />
                            </ng-container>
                          </ng-container>

                          <div class="mbtextarea">
                            <ng-container *ngIf="surveyStatus === true">
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (keypress)="msgComments($event, mc)"
                                >{{ mc.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                            <ng-container *ngIf="surveyStatus === false">
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (blur)="saveCatComments($event, mc)"
                                >{{ mc.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success save-comment"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <!-- Changes End Mitul-->
                  <!-- Change Color for sub category -->
                  <!--                                    <h2 class="about-head">{{categoryTitle}}</h2>-->

                  <div
                    class="row row-priorities subc"
                    *ngFor="let subCategory of subCategories"
                  >
                    <div class="form-group">
                      <label
                        class="col-sm-4 col-xs-12 control-label text-success"
                      >
                        <a
                          [routerLink]="[
                            '/survey',
                            surveyId,
                            'category',
                            categoryId,
                            'subcategory',
                            subCategory.categoryId
                          ]"
                          style="color: #ef684a"
                        >
                          {{ subCategory.title }}
                        </a>
                      </label>

                      <div class="col-lg-5 col-sm-8 col-xs-10">
                        <div class="form-group">
                          <ng-container
                            *ngIf="subCategory.satisfactionLevel !== undefined"
                          >
                            <ng-container
                              *ngIf="subCategory.satisfactionLevel !== ''"
                            >
                              <mat-slider
                                min="0"
                                max="100"
                                thumbLabel
                                [disabled]="surveyStatus"
                                [(ngModel)]="subCategory.satisfactionLevel"
                                (change)="
                                  setSatisfactionLevel($event, subCategory)
                                "
                              ></mat-slider>
                            </ng-container>
                            <ng-container
                              *ngIf="subCategory.satisfactionLevel === ''"
                            >
                              <ng-container
                                *ngIf="subCategory.satisfactionLevel === '0'"
                              >
                                <mat-slider
                                  min="0"
                                  max="100"
                                  thumbLabel
                                  [disabled]="surveyStatus"
                                  [(ngModel)]="subCategory.satisfactionLevel"
                                  (change)="
                                    setSatisfactionLevel($event, subCategory)
                                  "
                                ></mat-slider>
                              </ng-container>
                              <ng-container
                                *ngIf="subCategory.satisfactionLevel !== '0'"
                              >
                                <mat-slider
                                  min="0"
                                  max="100"
                                  thumbLabel
                                  [disabled]="surveyStatus"
                                  value="50"
                                  (change)="
                                    setSatisfactionLevel($event, subCategory)
                                  "
                                ></mat-slider>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container
                            *ngIf="subCategory.satisfactionLevel === undefined"
                          >
                            <mat-slider
                              min="0"
                              max="100"
                              thumbLabel
                              [disabled]="surveyStatus"
                              value="50"
                              (change)="
                                setSatisfactionLevel($event, subCategory)
                              "
                            ></mat-slider>
                          </ng-container>
                          <!-- <span class="rangeslider">
                                                    <input type="text" value="{{category.satisfactionLevel}}" name="range" class="range" (change)="saveSurveyCategoriesUserResponse(category.categoryId)"/>
                                                </span> -->
                        </div>
                      </div>
                      <div
                        class="col-xs-3 text-right cadnew2 srv-cat-sz-nw nw-width-auto"
                      >
                        <div *ngIf="isValues === true">
                          <ng-container *ngIf="subCategory.expressions !== ''">
                            <div
                              class="absVal"
                              style="
                                position: static;
                                font-size: 16px;
                                float: left;
                                padding: 4px 10px 0 0;
                              "
                            >
                              {{ subCategory.expressions }}
                            </div>
                          </ng-container>
                          <ng-container *ngIf="subCategory.expressions === ''">
                            <div
                              class="absVal"
                              style="
                                position: static;
                                font-size: 16px;
                                float: left;
                                padding: 4px 10px 0 0;
                                color: red;
                              "
                            >
                              N/A
                            </div>
                          </ng-container>
                        </div>
                        <ng-container *ngIf="subCategory.expressions !== ''">
                          <img
                            [src]="subCategory.expressions | parseEmoji"
                            style="max-width: 35px; vertical-align: top"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                            alt="Satisfaction emoji"
                          />
                        </ng-container>
                        <ng-container *ngIf="subCategory.expressions === ''">
                          <img
                            src="assets/imgs/emoji_new/emo0.png"
                            style="max-width: 35px; vertical-align: top"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                            alt="Default emoji"
                          />
                        </ng-container>
                        <!--<a href="#" data-toggle="tooltip" data-placement="top" title="Completed" class="completed">
                                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                                            </a>-->
                        <div class="submission question-submission nw-sub">
                          <a href="javascript:void(0)" class="comment">
                            <ng-container
                              *ngIf="subCategory.comments === undefined"
                            >
                              <img
                                class="commentimg"
                                src="assets/imgs/comment.png"
                                style="
                                  width: 36%;
                                  margin-top: -30px;
                                  margin-right: 23px !important;
                                "
                                alt="Comment"
                              />
                            </ng-container>
                            <!-- <ng-container *ngIf='subCategory.comments == ""'>
                                                        <img class="commentimg" src="assets/imgs/comment.png" style="width:36%;margin-top: -30px;margin-right:45px;">
                                                    </ng-container> -->
                            <ng-container
                              *ngIf="subCategory.comments !== undefined"
                            >
                              <ng-container *ngIf="subCategory.comments === ''">
                                <img
                                  class="commentimg"
                                  src="assets/imgs/comment.png"
                                  style="
                                    width: 36%;
                                    margin-top: -30px;
                                    margin-right: 23px !important;
                                  "
                                  alt="Comment"
                                />
                              </ng-container>
                              <ng-container *ngIf="subCategory.comments !== ''">
                                <img
                                  class="commentimg"
                                  src="assets/imgs/chat.png"
                                  style="
                                    width: 36%;
                                    margin-top: -30px;
                                    margin-right: 23px !important;
                                  "
                                  alt="Chat"
                                />
                              </ng-container>
                            </ng-container>

                            <!-- <img class="commentimg" src="assets/imgs/comment.png" style="width:36%;margin-top: -30px;margin-right:45px;"> -->

                            <div>
                              <ng-container *ngIf="surveyStatus === true">
                                <textarea
                                  placeholder="Your Comment"
                                  rows="3"
                                  class="comment-area"
                                  (keypress)="msgComments($event, subCategory)"
                                  >{{ subCategory.comments }}</textarea
                                >
                                <button
                                  type="button"
                                  class="btn btn-success close-comment"
                                >
                                  X
                                </button>
                              </ng-container>
                              <ng-container *ngIf="surveyStatus === false">
                                <textarea
                                  placeholder="Your Comment"
                                  rows="3"
                                  class="comment-area"
                                  (blur)="saveComments($event, subCategory)"
                                  >{{ subCategory.comments }}</textarea
                                >
                                <button
                                  type="button"
                                  class="btn btn-success save-comment"
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success close-comment"
                                >
                                  X
                                </button>
                              </ng-container>
                            </div>
                          </a>
                        </div>

                        <a
                          [routerLink]="[
                            '/survey',
                            surveyId,
                            'category',
                            categoryId,
                            'subcategory',
                            subCategory.categoryId
                          ]"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Start Survey"
                          class="start-survey"
                        >
                          <img
                            src="assets/imgs/right.png"
                            class="arrow-img"
                            style="
                              max-width: 35px;
                              vertical-align: top;
                              margin-left: 10px;
                            "
                            alt="Right arrow"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row row-about4">
                    <div class="form-group">
                      <div class="col-sm-6" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav1">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <!--                                                            <a routerLink="/about" style="padding-right:20px; border-right: 1px solid #a59090;">-->
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>
                            <!--                                                        <li>
                                                                                                                <a [routerLink]="['/survey', surveyId]" style="padding-right:10px; border-right: 1px solid #a59090;">
                                                                                                                    <img src="assets/imgs/category.png" title="Back to Main Categories">
                                                                                                                </a>
                                                                                                            </li>-->

                            <!-- <ng-container *ngFor="let category of categories">
                                                        <li>
                                                            <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                            </a>
                                                        </li>
                                                    </ng-container> -->
                            <!--<ng-container *ngFor="let category of categories">
                                                        <ng-container *ngFor="let newcategory of newcategories">
                                                            <ng-container *ngIf="category.categoryId == newcategory.categoryId">
                                                                <ng-container *ngIf="newcategory.status == 'completed'">
                                                                    <ng-container *ngIf="newcategory.title == 'Meaningful Work'">
                                                                        <li>
                                                                            <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                <img src="assets/imgs/newMeaningful Work.png" title="{{newcategory.title}}">
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>

                                                                    <ng-container *ngIf="newcategory.title == 'Compensation and Benefits'">
                                                                        <li>
                                                                            <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                <img src="assets/imgs/newCB.png" title="{{newcategory.title}}">
                                                                            </a>
                                                                        </li>

                                                                    </ng-container>

                                                                    <ng-container *ngIf="newcategory.title == 'Career Future'">
                                                                        <li>
                                                                            <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                <img src="assets/imgs/newCF.png" title="{{newcategory.title}}">
                                                                            </a>
                                                                        </li>

                                                                    </ng-container>

                                                                    <ng-container *ngIf="newcategory.title == 'Corporate Management'">
                                                                        <li>
                                                                            <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                <img src="assets/imgs/newCM.png" title="{{newcategory.title}}">
                                                                            </a>
                                                                        </li>

                                                                    </ng-container>

                                                                    <ng-container *ngIf="newcategory.title == 'Environment'">
                                                                        <li>
                                                                            <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                <img src="assets/imgs/newenv.png" title="{{newcategory.title}}">
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="newcategory.status == 'incompleted'">
                                                                    <li>
                                                                        <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                            <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                                        </a>
                                                                    </li>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>-->
                          </ul>
                        </div>
                      </div>
                      <div class="col-sm-6" align="right">
                        <div
                          class="btn-group btn-group-lg"
                          role="group"
                          aria-label="Next Previous button group"
                        >
                          <button
                            type="button"
                            class="btn btn-default prev-btn"
                            (click)="moveToPreviousStep()"
                          >
                            &#171;&nbsp;Previous
                          </button>
                          <button
                            type="button"
                            class="btn btn-default next-btn"
                            (click)="moveToNextStep()"
                          >
                            Next&nbsp;&#187;
                          </button>
                        </div>
                        <!-- <ng-container *ngIf='surveyStatus == false'>
                                                <ng-container *ngIf="count == 5">
                                                    <button type="button" class="btn btn-sm btn-primary btn-about" (click)="submitSurvey()">SUBMIT
                                                        <img style="max-width: 30px;margin-left: 3px;" src="assets/imgs/submit.png">
                                                    </button>
                                                </ng-container>

                                                <ng-container *ngIf="count != 5">
                                                    <a href="#" data-toggle="modal" data-target="#submitModalSubCat" class="btn btn-sm btn-primary btn-about" style="opacity: 0.7;">SUBMIT
                                                        <img style="max-width: 30px;margin-left: 3px;" src="assets/imgs/submit.png">
                                                    </a>
                                                </ng-container>
                                            </ng-container> -->
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- END panel-->
        </div>
      </div>
    </div>
    <!-- END row-->
  </section>

  <div class="modal fade" id="submitModalSubCat" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 class="modal-title">Submit Survey</h4>
        </div>
        <div class="modal-body">
          <p>
            You have not yet answered all the questions in the survey.
            Submitting it now might provide incomplete or incorrect results.
            Would you like to submit the survey anyway?
          </p>
          <button
            type="button"
            class="btn btn-sm btn-primary btn-about"
            (click)="submitSurvey()"
          >
            SUBMIT NOW
            <img
              style="max-width: 30px; margin-left: 3px"
              src="assets/imgs/submit.png"
              alt="Submit icon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
