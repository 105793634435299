<div class="container">
  <div class="servey-part-out servey-part-out2">
    <div class="servey-part">
      <h1>
        <span class="c1">Career Value</span>
        <span class="c2">Satisfaction</span> <span class="c3">Weight</span>
      </h1>
      <h2>
        <span class="c1">{{ SurveyTitle.title }}</span>
        <span class="c2"
          ><img
            [src]="getExpressionsOrDefault(SurveyTitle.expressions)"
            style="max-width: 35px; vertical-align: top"
            class="catemojis sanchit"
            data-toggle="tooltip"
            data-placement="top"
            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
          />
          <small>{{ SurveyTitle.expressions || "-" }}</small>
        </span>
      </h2>
      <div class="servey-one" *ngFor="let cat of newcategories">
        <h3>
          <span class="c1">{{ cat.title }}</span>
          <span class="c2">
            <img
              [src]="getExpressionsOrDefault(cat.expressions)"
              style="max-width: 35px; vertical-align: top"
              class="catemojis sanchit"
              data-toggle="tooltip"
              data-placement="top"
              title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
            />
            <small *ngIf="cat.expressions">{{ cat.expressions }}</small>
            <small *ngIf="!cat.expressions">-</small>
          </span>
          <span class="c3" *ngIf="cat.satisfactionLevel">
            <!-- <mat-slider min="0" max="100" thumbLabel disabled [value]="cat.satisfactionLevel"></mat-slider> -->
            {{ cat.satisfactionLevel }}
          </span>
          <span class="c3" *ngIf="!cat.satisfactionLevel">
            <!-- <mat-slider min="0" max="100" thumbLabel disabled value="0"></mat-slider> -->
            -
          </span>
          <span class="c4">
            <a (click)="openModel('catComment', cat)">
              <img
                class="commentimg"
                src="assets/imgs/chat.png"
                style="
                  width: 36%;
                  margin-top: -30px;
                  margin-right: 23px !important;
                "
              />
            </a>
          </span>
          <!-- <button (click)="openModel('catComment',cat)">Comment</button> -->
        </h3>
        <h4 *ngIf="cat.sub_category">
          <p *ngFor="let subcat of cat.sub_category">
            <span>
              <strong class="c1">
                {{ subcat.title }}
              </strong>
              <span class="c2">
                <img
                  [src]="getExpressionsOrDefault(subcat.expressions)"
                  style="max-width: 35px; vertical-align: top"
                  class="catemojis sanchit"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                />
                <small *ngIf="subcat.expressions">{{
                  subcat.expressions
                }}</small>
                <small *ngIf="!subcat.expressions">-</small>
              </span>
              <span class="c3" *ngIf="subcat.satisfactionLevel">
                <!-- <mat-slider min="0" max="100" thumbLabel disabled [value]="subcat.satisfactionLevel"></mat-slider> -->
                {{ subcat.satisfactionLevel }}
              </span>
              <span class="c3" *ngIf="!subcat.satisfactionLevel">
                <!-- <mat-slider min="0" max="100" thumbLabel disabled value="0"></mat-slider> -->
                -
              </span>
              <span class="c4">
                <a (click)="openModel('subCatComment', subcat)">
                  <img
                    class="commentimg"
                    src="assets/imgs/chat.png"
                    style="
                      width: 36%;
                      margin-top: -30px;
                      margin-right: 23px !important;
                    "
                  />
                </a>
              </span>

              <!-- <button (click)="openModel('subCatComment',subcat)">Comment</button> -->

              <p style="color: gray" *ngFor="let ques of subcat.questions">
                <span class="c1">{{ ques.content }}</span>
                <span class="c2">
                  <img
                    [src]="getExpressionsOrDefault(ques.expressions)"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis sanchit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                  />
                  <small *ngIf="ques.expressions">{{
                    ques.expressions | expression
                  }}</small>
                  <small *ngIf="!ques.expressions">-</small>
                </span>
                <span class="c3" *ngIf="ques.satisfactionLevel">
                  <!-- <mat-slider min="0" max="100" thumbLabel disabled [value]="ques.satisfactionLevel"></mat-slider> -->
                  {{ ques.satisfactionLevel }}
                </span>
                <span class="c3" *ngIf="!ques.satisfactionLevel">
                  <!-- <mat-slider min="0" max="100" thumbLabel disabled value="0"></mat-slider> -->
                  -</span
                >

                <span class="c4">
                  <a (click)="openModel('subCatQuestionComment', ques)">
                    <img
                      class="commentimg"
                      src="assets/imgs/chat.png"
                      style="
                        width: 36%;
                        margin-top: -30px;
                        margin-right: 23px !important;
                      "
                    />
                  </a>
                </span>
                <!-- <button (click)="openModel('subCatQuestionComment',ques)">Comment</button> -->
              </p>
            </span>
          </p>
        </h4>
        <h4 *ngIf="!cat.sub_category">
          <p style="color: gray" *ngFor="let ques of cat.questions">
            <span class="c1">{{ ques.content }}</span>
            <span class="c2">
              <img
                [src]="getExpressionsOrDefault(ques.expressions)"
                style="max-width: 35px; vertical-align: top"
                class="catemojis sanchit"
                data-toggle="tooltip"
                data-placement="top"
                title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
              />
              <small>{{ ques.expressions }}</small>
            </span>
            <span class="c3" *ngIf="ques.satisfactionLevel">
              <!-- <mat-slider min="0" max="100" thumbLabel disabled [value]="ques.satisfactionLevel"></mat-slider> -->
              {{ ques.satisfactionLevel }}
            </span>
            <span class="c3" *ngIf="!ques.satisfactionLevel">
              <!-- <mat-slider min="0" max="100" thumbLabel disabled value="0"></mat-slider> -->
              -
            </span>
            <span class="c4">
              <a (click)="openModel('catQuestionComment', ques)">
                <img
                  class="commentimg"
                  src="assets/imgs/chat.png"
                  style="
                    width: 36%;
                    margin-top: -30px;
                    margin-right: 23px !important;
                  "
                />
              </a>
            </span>
            <!-- <button (click)="openModel('catQuestionComment',ques)">Comment</button> -->
          </p>
        </h4>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="publishedModel" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
        <h4 class="modal-title">Publishe Result</h4>
      </div>
      <div class="modal-body">
        <table class="table" *ngIf="viewComment.length > 0">
          <thead>
            <tr>
              <th>User Comment</th>
              <th>Manager Comment</th>
            </tr>
          </thead>
          <tbody *ngFor="let vc of viewComment">
            <tr>
              <td>
                <textarea disabled>{{ vc.comments }}</textarea>
              </td>
              <td>
                <textarea disabled>{{ vc.managerComment }}</textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <p *ngIf="viewComment.length == 0">No Comments Found</p>
        <button
          type="button"
          class="btn btn-sm btn-primary btn-about"
          data-dismiss="modal"
        >
          Close
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</div>
