import { Component, OnInit, AfterViewChecked, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';
import { Category } from './../types/category';
import { newCategory } from './../types/newcategory';
import { Question } from './../types/question';
import { OPTIONAL } from './../../config/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.css'],
  standalone: false,
})
export class SurveyQuestionComponent implements OnInit, AfterViewChecked {
  currentUserId: string;
  surveyId: string;
  categoryId: string;
  subCategoryId: string;
  selectedCategoryId: string;
  categoryTitle: string;
  categories: Category[];
  newcategories: newCategory[];
  questions: Question[];
  fbReactionsInitialized: boolean;
  nextCatId: string;
  nextCatIsParent: boolean;
  prevCatId: string;
  prevCatIsParent: boolean;
  contentAvailable: boolean;
  emo: any;
  count: number;
  surveyStatus: any;
  subCategories: Category[];
  isValues: boolean;
  isDisabled: boolean;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.categoryId = '';
    this.subCategoryId = '';
    this.selectedCategoryId = '';
    this.categoryTitle = '';
    this.categories = [];
    this.newcategories = [];
    this.questions = [];
    this.fbReactionsInitialized = false;
    this.nextCatId = '';
    this.nextCatIsParent = false;
    this.prevCatId = '';
    this.prevCatIsParent = false;
    this.contentAvailable = false;
    this.count = 0;
    this.surveyStatus = '';
    this.subCategories = [];
    this.isValues = false;
    this.isDisabled = false;
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (
        params['surveyId'] !== undefined &&
        params['categoryId'] !== undefined &&
        params['subCategoryId'] !== undefined
      ) {
        this.surveyId = params['surveyId'];
        this.categoryId = params['categoryId'];
        this.subCategoryId = params['subCategoryId'];
        this.fbReactionsInitialized = false;
        this.contentAvailable = false;

        // Select the category id to be used to get questions data.
        this.selectedCategoryId =
          this.subCategoryId != 'none' ? this.subCategoryId : this.categoryId;

        this.getCategory();
        this.getSurveyCategories();
        this.getQuestions();
        this.getSurveyNewChangeCategories();
        this.getSurveyStatus();
        this.getSettings();
        this.getSurveySubcategoriesUserResponse();
        this.loadNextCategoryData();
        this.loadPrevCategoryData();
        this.getSurveyCategoryUserResponse();
      }
    });
  }

  ngAfterViewChecked(): void {
    if (!this.fbReactionsInitialized) {
      if (jQuery('.FB_reactions').length > 0) {
        // Initialize facebook reactions plugin.
        var x = $('._bar');
        // $("._bar").not(':last-child').remove()
        if (x.length > 2) {
          $('._bar').remove();
          jQuery('.FB_reactions').facebookReactions({});
        } else {
          jQuery('.FB_reactions').facebookReactions({});
        }

        this.fbReactionsInitialized = true;

        // Update slider width on hover event of the emoji.
        $('.FB_reactions').hover(
          function () {
            // $("._bar").not(':last-child').remove();
            $(this)
              .parent()
              .children('.submission .mat-slider')
              .css('width', '25%');
          },
          function () {
            // $("._bar").not(':last-child').remove();
            $(this)
              .parent()
              .children('.submission .mat-slider')
              .css('width', '65%');
          }
        );

        jQuery('.emoji').on('click', () => {
          // $("._bar").not(':last-child').remove();
          $(this)
            .parents('a.FB_reactions')
            .next()
            .val($(this).data('emoji-value'));

          $('.FB_reactions div._bar').fadeIn(100);
          //$('.FB_reactions div._bar').remove();
          $('.FB_reactions')
            .parent()
            .children('.submission .mat-slider')
            .css('width', '65%');
          $(this).parents('a.FB_reactions').next().trigger('click');
        });

        // Bind a handler to the "click" event of comment icon/image.
        $(document).on('click', '.comment img', function () {
          $(this)
            .parent()
            .children('.comment div')
            .fadeIn(250, function () {
              $(this).children('.comment-area').focus();
            });
        });

        // Bind a handler to the "click" event of Submit/Close button in the comment box.
        $('.comment button.save-comment, .comment button.close-comment').on(
          'click',
          function () {
            $(this).parent().parent().children('.comment div').fadeOut(250);
          }
        );
      }
    }
  }

  getSettings(): void {
    this.surveyService.getSetting().subscribe(
      (data) => {
        if (data.data !== null) {
          if (data.data.showfrontvalue == '1') {
            this.isValues = true;
          } else {
            this.isValues = false;
          }
        } else {
          this.isValues = false;
        }
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getCategory(): void {
    this.surveyService.getCategory(this.selectedCategoryId).subscribe(
      (data) => {
        this.categoryTitle = data.category.title;
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getSurveyCategories(): void {
    this.categories = [];

    this.surveyService.getCategories(this.surveyId).subscribe(
      (data) => {
        data.categories.forEach((category: any) => {
          this.categories.push({
            userId: this.currentUserId,
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            categoryIcon: category.categoryIcon,
            title: category.title,
            satisfactionLevel: '',
            expressions: '',
            comments: category.comments,
          });
          if (category.status == 'completed') {
            this.count++;
          }
        });
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getQuestions(): void {
    this.surveyService.getQuestions(this.selectedCategoryId).subscribe(
      (data) => {
        // Reset questions array.
        this.questions = [];
        this.fbReactionsInitialized = false;
        this.contentAvailable = true;
        // Push all questions data into array.
        data.questions.forEach((question: any) => {
          this.questions.push({
            _id: question._id,
            categoryId: question.categoryId,
            content: question.content,
            satisfactionLevel: '',
            expressions: '',
            comments: '',
            nosValue: '',
          });
        });

        this.getQuestionsResponse();
      },
      (error) => {
        this.contentAvailable = true;
        console.log('Failed to get questions data.');
        console.log(error);
      }
    );
  }

  getQuestionsResponse(): void {
    this.surveyService
      .getSurveyQuestionsUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: this.categoryId,
        categoryId: this.subCategoryId,
      })
      .subscribe(
        (data) => {
          if (data.questionsResponse.length > 0) {
            data.questionsResponse.forEach((response: any) => {
              this.questions.forEach((thisQuestion) => {
                if (thisQuestion._id == response.questionId) {
                  thisQuestion.satisfactionLevel = response.satisfactionLevel
                    ? response.satisfactionLevel
                    : 0;

                  thisQuestion.expressions = response.expressions
                    ? response.expressions
                    : '';

                  thisQuestion.comments = response.comments
                    ? response.comments
                    : '';

                  thisQuestion.nosValue = response.nosValue
                    ? (parseFloat(response.nosValue) * 100).toFixed(0)
                    : '';
                }
              });
            });
          }

          this.fbReactionsInitialized = false;
        },
        (error) => {
          console.log('getQuestionsResponse error callback');
          console.log('Failed to get questions response data.');
          console.log(error);
        }
      );
  }

  submitSurvey(): void {
    this.surveyService
      .submitSurvey({
        userId: this.currentUserId,
        surveyId: this.surveyId,
      })
      .subscribe(
        (data) => {
          // console.log("Survey status updated successfully.", data);
          // Navigate to the dashboard.
          jQuery('#submitModal').modal('hide');
          this.router.navigate(['/dashboard']);
          // Show notification message (using Snackbar component).
          this.snackBar.open(
            'Thank you, your survey has been submitted, and we will share the results with you shortly.',
            '',
            {
              duration: 5000,
            }
          );
        },
        (error) => {
          console.log('Failed to update survey status.');
          console.log(error);
        }
      );
  }

  loadNextCategoryData(): void {
    this.surveyService
      .getNextCategory({
        surveyId: this.surveyId,
        catId: this.categoryId,
        subCatId: this.subCategoryId,
      })
      .subscribe(
        (data) => {
          this.nextCatId = data.catId;
          this.nextCatIsParent = data.isParent;
        },
        (error) => {
          console.log('Failed to get next category data.');
          console.log(error);
        }
      );
  }

  loadPrevCategoryData(): void {
    this.surveyService
      .getPrevCategory({
        surveyId: this.surveyId,
        catId: this.categoryId,
        subCatId: this.subCategoryId,
      })
      .subscribe(
        (data) => {
          this.prevCatId = data.catId;
          this.prevCatIsParent = data.isParent;
        },
        (error) => {
          console.log('Failed to get next category data.');
          console.log(error);
        }
      );
  }

  /**
   * This is the sub-category questions listing page, on click of "Previous" - it navigates to the previous sub-category page or category page.
   */
  moveToPreviousStep(): void {
    if (this.prevCatId != '') {
      if (this.prevCatIsParent) {
        this.router.navigate([
          '/survey',
          this.surveyId,
          'category',
          this.prevCatId,
        ]);
      } else {
        this.router.navigate([
          '/survey',
          this.surveyId,
          'category',
          this.categoryId,
          'subcategory',
          this.prevCatId,
        ]);
      }
    } else {
      this.router.navigate(['/survey', this.surveyId]);
    }
  }

  /**
   * This is the sub-category questions listing page, on click of "Next" - it navigates to the first sub-category page.
   */
  moveToNextStep(): void {
    if (this.nextCatIsParent) {
      this.router.navigate([
        '/survey',
        this.surveyId,
        'category',
        this.nextCatId,
      ]);
    } else {
      this.router.navigate([
        '/survey',
        this.surveyId,
        'category',
        this.categoryId,
        'subcategory',
        this.nextCatId,
      ]);
    }
  }

  saveSatisfactionLevel(event: any, question: Question): void {
    // Get satisfaction level.
    let satisfactionLevel: number = event.value;

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: this.categoryId,
      categoryId: this.subCategoryId,
      questionId: question._id,
      satisfactionLevel: satisfactionLevel,
    });
  }

  saveComments(event: any, question: Question): void {
    // Get comments.
    let comments: string = event.target.value;

    // Hide the comment box.
    $(event.target).parent().fadeOut(250);

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: this.categoryId,
      categoryId: this.subCategoryId,
      questionId: question._id,
      comments: comments,
    });
  }

  msgComments(event: any, question: Question): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  saveReaction(event: any, question: Question): void {
    let reaction: string = event.target.value;
    this.isDisabled = true;
    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: this.categoryId,
      categoryId: this.subCategoryId,
      questionId: question._id,
      expressions: reaction,
    });
  }

  saveUserResponse(data: any): void {
    //$(".prev-btn").attr("disabled", "true");
    //$(".next-btn").attr("disabled", "true");
    this.surveyService.saveSurveyQuestionUserResponse(data).subscribe(
      (data) => {
        // Write a code to show some notification if required.
        // console.log("saveUserResponse success callback...");
        // console.log(data);

        this.fbReactionsInitialized = true;
        this.getQuestionsResponse();
        this.getSurveySubcategoriesUserResponse();

        this.isDisabled = false;
        // $(".prev-btn").removeAttr("disabled");
        // $(".next-btn").removeAttr("disabled");
      },
      (error) => {
        console.log('Failed to save user response.');
        console.log(error);
      }
    );
  }

  getSurveyNewChangeCategories(): void {
    this.newcategories = [];

    let data = 'userId=' + this.currentUserId + '&surveyId=' + this.surveyId;

    this.surveyService.getNewChangeCategories(data).subscribe(
      (data) => {
        data.data.forEach((category: any) => {
          this.newcategories.push({
            userId: this.currentUserId,
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            title: category.title,
            status: category.status,
            satisfactionLevel: '',
            expressions: '',
          });

          if (category.status == 'completed') {
            this.count++;
          }
        });

        // this.getSurveyNewChangeCategoriesUserResponse();
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getSurveyStatus(): void {
    this.surveyService.getSurveyStatus(this.currentUserId).subscribe(
      (data) => {
        if (data.results.length > 0) {
          data.results.forEach((survey: any) => {
            if (this.surveyId == survey.surveyId) {
              if (survey.status == 'COMPLETED') {
                this.surveyStatus = 'emojiDisabledClass';
              } else {
                this.surveyStatus = false;
              }
            }
          });

          // if (data.results[0].status == "COMPLETED") {
          //     this.surveyStatus = "emojiDisabledClass";
          // } else {
          //     this.surveyStatus = false;
          // }
        }
      },
      (error) => {
        console.log('Failed to get user survey status data.');
        console.log(error);
      }
    );
  }
  getSurveySubcategoriesUserResponse(): void {
    var data;
    if (this.subCategoryId === 'none') {
      data = {
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: '',
      };
    } else {
      data = {
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: this.categoryId,
      };
    }
    this.surveyService.getSurveyCategoriesUserResponse(data).subscribe(
      (data) => {
        this.subCategories = [];
        this.contentAvailable = true;
        this.subCategories.push({
          userId: this.currentUserId,
          surveyId: this.surveyId,
          parentId: this.categoryId,
          categoryId: this.subCategoryId,
          description: '',
          categoryIcon: '',
          title: '',
          satisfactionLevel: '',
          expressions: '',
          comments: '',
        });
        if (data.categories.length > 0) {
          data.categories.forEach((category: any) => {
            if (category.parentCategoryId === '') {
              if (this.categoryId == category.categoryId) {
                this.subCategories[0].satisfactionLevel =
                  category.satisfactionLevel;
                this.subCategories[0].comments = category.comments;
                this.subCategories[0].expressions = category.expressions
                  ? category.expressions
                  : '';
              }
            } else {
              if (this.subCategoryId == category.categoryId) {
                this.subCategories[0].satisfactionLevel =
                  category.satisfactionLevel;
                this.subCategories[0].comments = category.comments;
                this.subCategories[0].expressions = category.expressions
                  ? category.expressions
                  : '';
              }
            }
          });
        }
      },
      (error) => {
        this.contentAvailable = true;
        console.log('Failed');
        console.log(error);
      }
    );
  }
  saveComments1(event: any, subCategory: Category): void {
    // Get comments.
    let comments: string = event.target.value;

    // Hide the comment box.
    $(event.target).parent().fadeOut(250);
    if (this.subCategoryId === 'none') {
      var data = {
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: '',
        categoryId: this.categoryId,
        comments: comments,
      };
    } else {
      var data = {
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: this.categoryId,
        categoryId: this.subCategoryId,
        comments: comments,
      };
    }
    //  {
    //     "userId": this.currentUserId,
    //     "surveyId": this.surveyId,
    //     "parentCategoryId": subCategory.parentId, // this is a sub-category
    //     "categoryId": subCategory.categoryId,
    //     "comments": comments
    // }
    this.saveUserResponse1(data);
  }

  msgComments1(event: any, category: Category): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  msgCommentsSubCategory(event: any, subCategory: Category): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  saveUserResponse1(data: any): void {
    $('.prev-btn').attr('disabled', 'true');
    $('.next-btn').attr('disabled', 'true');
    this.surveyService.saveSurveyCategoriesUserResponse(data).subscribe(
      (data) => {
        // Write a code to show some notification if required.
        // console.log("saveUserResponse success callback...");
        // console.log(data);
        // this.widgetsInitialized = false;
        this.getSurveySubcategoriesUserResponse();
        $('.prev-btn').removeAttr('disabled');
        $('.next-btn').removeAttr('disabled');
      },
      (error) => {
        console.log('Failed to save user response.');
        console.log(error);
      }
    );
  }

  getSurveyCategoryUserResponse(): void {
    this.surveyService
      .getSurveyCategoryUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        categoryId: this.categoryId,
      })
      .subscribe(
        (data) => {
          if (data.categories.length > 0) {
            if (data.categories[0].satisfactionLevel < OPTIONAL) {
              setTimeout(function () {
                $('.subc').css('opacity', '0.5');
              }, 200);
            } else {
              setTimeout(function () {
                $('.subc').css('opacity', '1');
              }, 200);
            }
          }
        },
        (error) => {
          console.log('Failed');
          console.log(error);
        }
      );
  }
}
