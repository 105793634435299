import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: false,
})
export class HomeComponent implements OnInit {
  currentUserId: string | null;
  title: string;
  content: string;
  userContent: boolean;
  @ViewChild('dataContainer') dataContainer!: ElementRef;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.userContent = false;
    this.title = '';
    this.content = '';
  }

  ngOnInit(): void {
    if (this.currentUserId !== null) {
      this.userContent = true;
    } else {
      this.userContent = false;
    }

    const currentUserStr = localStorage.getItem('currentUser');
    const currentUser = currentUserStr ? JSON.parse(currentUserStr) : null;
    if (currentUser && currentUser.accessToken) {
      this.getSettings();
    }
  }

  redirectToDashBoard(): void {
    this.router.navigate(['/dashboard']);
  }

  getSettings(): void {
    this.surveyService.getSetting().subscribe({
      next: (data: any) => {
        if (data.data !== null) {
          if (data.data.title !== 'undefined') {
            if (data.data.title !== '') {
              this.title = data.data.title;
            } else {
              this.title = 'Welcome';
            }
          } else {
            this.title = 'Welcome';
          }

          if (data.data.content !== 'undefined') {
            if (data.data.content !== '') {
              this.dataContainer.nativeElement.innerHTML = data.data.content;
            } else {
              this.dataContainer.nativeElement.innerHTML = '';
            }
          } else {
            this.dataContainer.nativeElement.innerHTML = '';
          }
        } else {
          this.title = 'Welcome';
          this.dataContainer.nativeElement.innerHTML = '';
        }
      },
      error: (error: any) => {
        console.log('Failed to get category data.');
        console.log(error);
      },
    });
  }
}
