<div class="front container-fluid">
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid" *ngIf="userContent === false">
        <div class="row row-front">
          <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
            <video class="video" controls>
              <source src="assets/video/demo.mp4" type="video/mp4" />
              Your browser does not support this video.
            </video>
            <div class="playpause"></div>
          </div>
        </div>
        <div class="row row-index">
          <div class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </div>
        </div>
        <div class="row">
          <div
            class="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1"
            align="center"
          >
            <a routerLink="/dashboard" class="btn btn-sm btn-primary btn-index"
              >Start</a
            >
          </div>
        </div>
      </div>
      <!-- END row-->
      <!-- Start content -->

      <div class="container cntnt1" *ngIf="userContent === true">
        <div class="panel panel-default">
          <!--                    <div class="panel-body">
                        <div>
                            <h3>  {{title}}   </h3>
                        </div>
                        <div #dataContainer>                             
                        </div>                        
                        <div>
                            <button class="btn btn-default pull-right" (click)="redirectToDashBoard()">
                                Next
                            </button>
                        </div>
                    </div>-->
          <div class="panel-body">
            <div>
              <h3>Welcome to the Happy Meter</h3>
            </div>
            <div>The Happy Meter has several purposes</div>
            <div>
              <ul>
                <li>
                  It gives a measure of how satisfied one is with their career.
                  Users reflect on what is important in their career.
                </li>
                <li>
                  Employees and management can see how satisfied their employees
                  are relative to similar companies.
                </li>
                <li>
                  Employee criticism is made public. Corporate officers can
                  review criticism and provide management perspective. Often
                  management makes adjustment to policy and procedures in
                  response to criticism.
                </li>
              </ul>
            </div>
            <div>
              Happy Meter measures satisfaction that is based upon common
              values. While everyone shares common values they weight importance
              of these values very differently. Happy Meter users construct bar
              charts to show the weight given to each career value. Satisfaction
              is shown by the choice of a happy face.
            </div>
            <div>
              You have 14 days to complete the servey and have it include in the
              result. At the conclusion of the survey process, you will see an
              how your company rated and compare your company with similar
              companies that chose to share the survey.
            </div>
            <div>
              This survey is confidential and the smallest identifiable at least
              10 employees.
            </div>
            <div>
              <button
                class="btn btn-default pull-right"
                (click)="redirectToDashBoard()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End content -->
    </div>
  </section>
</div>
