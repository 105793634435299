<header>
  <nav class="navbar navbar-fixed-top">
    <div class="container-fluid">
      <div class="navbar-header">
        <button
          id="menubtn"
          type="button"
          class="navbar-toggle"
          data-toggle="collapse"
          data-target="#myNavbar"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" routerLink="/">
          <img src="assets/imgs/logo.png" alt="HappyMeter Logo" />
        </a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right">
          <ng-container *ngIf="!isLoggedIn">
            <li>
              <!--<a routerLink="/">Home</a>-->
              <a (click)="home()" role="button">Home</a>
            </li>
            <li>
              <!--<a routerLink="/get-started">Log in</a>-->
              <a (click)="login()" role="button">Log in</a>
            </li>
          </ng-container>

          <ng-container *ngIf="isLoggedIn">
            <li>
              <!--<a routerLink="/dashboard">Dashboard</a>-->
              <a (click)="dashboard()" role="button">Dashboard</a>
            </li>
            <!--<li><a routerLink="/about">About</a></li>-->
            <li>
              <a (click)="logout()" role="button">Log out</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</header>

<router-outlet></router-outlet>

<footer>
  &copy; {{ today | date : "y" }} HappyMeter
  <!-- <div class="container-fluid" style="text-align: left;float: left;font-size: 13px;">
      For best performance, please use Google Chrome, Safari or Mozilla Firefox browser's latest versions.
  </div> -->
</footer>
