import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '../../config/constants';
import { Observable, of } from 'rxjs';
import { SignUp } from '../signin-signup/signup';
import { SignIn } from '../signin-signup/signin';
import { UserProfile } from './../user-profile/user-profile';
import { ResetPassword } from './../reset-password/reset-password';
import { ForgotPassword } from '../signin-signup/forgot-password';
import { SignupResponseType } from '../api-response-types/signup-response-type';
import { AboutResponseType } from '../api-response-types/about-response-type';
import { SigninResponseType } from '../api-response-types/signin-response-type';
import { CheckLinkResponseType } from '../api-response-types/checklink-response-type';
import { ForgotPwdResponseType } from '../api-response-types/forgotpwd-response-type';
import { ResetPasswordResponseType } from '../api-response-types/resetpassword-response-type';
import { emailVerificationResponseType } from '../api-response-types/emailverification-response-type';
import { UserDataResponse } from '../api-response-types/user-data-response';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  setUserLoggedIn(data: any): void {
    //data.isProfileComplete = true;
    localStorage.setItem('currentUser', JSON.stringify(data));
    localStorage.setItem(
      'isProfileCompleted',
      data.isProfileComplete.toString()
    );
  }

  unSetUserLoggedIn(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isProfileCompleted');
  }

  isLoggedIn(): boolean {
    return localStorage.getItem('currentUser') ? true : false;
  }

  isProfileCompleted(): boolean {
    return localStorage.getItem('isProfileCompleted') == 'true' ? true : false;
  }

  setProfileCompleted(value: string): void {
    localStorage.setItem('isProfileCompleted', value);
  }

  getCurrentUserId(): string {
    var currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser != null && currentUser.id) {
      return currentUser.id;
    } else {
      return '';
    }
  }

  signUp(signUpData: SignUp): Observable<SignupResponseType> {
    return this.http.post<SignupResponseType>(
      `${API_BASE_URL}api/users/signup`,
      signUpData
    );
  }

  signIn(signInData: SignIn): Observable<SigninResponseType> {
    return this.http.post<SigninResponseType>(
      `${API_BASE_URL}api/users/signin`,
      signInData
    );
  }

  verifyEmail(token: string): Observable<emailVerificationResponseType> {
    return this.http.get<emailVerificationResponseType>(
      `${API_BASE_URL}api/users/verify-email/${token}`
    );
  }

  forgotPassword(
    forgotPwdData: ForgotPassword
  ): Observable<SignupResponseType> {
    return this.http.post<SignupResponseType>(
      `${API_BASE_URL}api/users/forgot-pwd`,
      forgotPwdData
    );
  }

  checkLink(token: string): Observable<CheckLinkResponseType> {
    return this.http.get<CheckLinkResponseType>(
      `${API_BASE_URL}api/users/verify-link/${token}`
    );
  }

  resetPassword(
    resetPasswordData: ResetPassword
  ): Observable<ResetPasswordResponseType> {
    return this.http.post<ResetPasswordResponseType>(
      `${API_BASE_URL}api/users/reset-password`,
      resetPasswordData
    );
  }

  userAboutUpdate(
    id: string,
    userData: UserProfile
  ): Observable<AboutResponseType> {
    return this.http.post<AboutResponseType>(
      `${API_BASE_URL}api/users/update-profile/${id}`,
      userData
    );
  }

  getUserById(id: string): Observable<UserDataResponse> {
    return this.http.get<UserDataResponse>(
      `${API_BASE_URL}api/users/get-user-data/${id}`
    );
  }
  getState(): Observable<any> {
    return this.http.get<any>(`${API_BASE_URL}state/getStates`);
  }
  getCities(id: string): Observable<any> {
    return this.http.get<any>(`${API_BASE_URL}state/${id}/cities`);
  }
  signOut(): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/users/signout`, '');
  }
}
