import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emojiValue',
  standalone: false,
})
export class EmojiValuePipe implements PipeTransform {
  transform(expressions: string): any {
    let imagepath = 'assets/imgs/emoji_new/';
    if (expressions === 'emo10') {
      return imagepath + 'emo10.png';
    } else if (expressions === 'emo9') {
      return imagepath + 'emo9.png';
    } else if (expressions === 'emo8') {
      return imagepath + 'emo8.png';
    } else if (expressions === 'emo7') {
      return imagepath + 'emo7.png';
    } else if (expressions === 'emo5') {
      return imagepath + 'emo5.png';
    } else if (expressions === 'emo4') {
      return imagepath + 'emo4.png';
    } else if (expressions === 'emo3') {
      return imagepath + 'emo3.png';
    } else if (expressions === 'emo2') {
      return imagepath + 'emo2.png';
    } else if (expressions === 'emo1') {
      return imagepath + 'emo1.png';
    } else {
      return imagepath + 'emo0.png';
    }
  }
}
