<div class="container">
  <div class="sev-met-title">
    <div class="backArrow">
      <a (click)="backtometric()"
        ><i class="fa fa-angle-left" aria-hidden="true"></i> Back to Main
        Results</a
      >
    </div>
    <ng-container *ngFor="let data of survey_data">
      <p>Response Analysis: {{ data.content }}</p>
    </ng-container>
  </div>
  <div class="servey-part-out servey-part-out2 sev-met-table">
    <div class="servey-part">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-xs-12">
          <table>
            <tr>
              <th></th>
              <th>Score</th>
              <th>Importance</th>
            </tr>
            <ng-container *ngFor="let data of survey_data">
              <tr>
                <td>{{ uname }}</td>
                <td>
                  <img
                    [src]="data.uexpressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis"
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </td>
                <td>
                  <mat-slider
                    [min]="0"
                    [max]="100"
                    [displayWith]="formatLabel"
                    [disabled]="true"
                    [(ngModel)]="data.usatisfactionLevel"
                  ></mat-slider>
                </td>
              </tr>
              <ng-container *ngIf="depFilter">
                <tr>
                  <td>{{ dname }}</td>
                  <td>
                    <img
                      [src]="data.dexpressions | parseEmoji"
                      style="max-width: 35px; vertical-align: top"
                      class="catemojis"
                      data-toggle="tooltip"
                      data-placement="top"
                    />
                  </td>
                  <td>
                    <mat-slider
                      [min]="0"
                      [max]="100"
                      [displayWith]="formatLabel"
                      [disabled]="true"
                      [(ngModel)]="data.dsatisfactionLevel"
                    ></mat-slider>
                  </td>
                </tr>
              </ng-container>
              <tr>
                <td>{{ oname }}</td>
                <td>
                  <img
                    [src]="data.oexpressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis"
                    data-toggle="tooltip"
                    data-placement="top"
                  />
                </td>
                <td>
                  <mat-slider
                    [min]="0"
                    [max]="100"
                    [displayWith]="formatLabel"
                    [disabled]="true"
                    [(ngModel)]="data.osatisfactionLevel"
                  ></mat-slider>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
        <div class="col-lg-6 col-md-6 col-xs-12">
          <table class="table-two">
            <tr>
              <th>Comments</th>
              <th>
                <a (click)="getComments()"
                  ><i class="fa fa-search-plus" aria-hidden="true"></i
                ></a>
              </th>
            </tr>
            <tr>
              <td colspan="2">
                <div
                  id="my_favorite_latin_words"
                  style="
                    width: 550px;
                    height: 350px;
                    border: 1px solid #ccc;
                    background-color: #fafafa;
                    font-family: 'Roboto';
                  "
                ></div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="commentModel" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
        <h4 class="modal-title">Comments</h4>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let data of survey_comments">
          <div class="datahtml">{{ data }}<span></span></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
