import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from './../services/user.service';
import { ResetPassword } from './reset-password';
// import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./../signin-signup/signin-signup.component.css'],
  standalone: false,
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordModel: ResetPassword;

  constructor(
    private userService: UserService,
    private router: Router,
    // private _flashMessagesService: FlashMessagesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.resetPasswordModel = new ResetPassword();
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const token = params.get('token');
      if (token !== null) {
        this.resetPasswordModel.token = token;
        this.userService.checkLink(token).subscribe({
          next: (data) => {
            if (data['data'] !== 'success') {
              this.router.navigate(['/get-started']);
              setTimeout(() => {
                // this._flashMessagesService.show("This link has expired or invalid link.", { cssClass: 'alert-danger', timeout: 5000 });
              }, 500);
            } else {
              //Do nothing.
            }
          },
          error: (error) => {
            console.log('Error while getting Establishment data');
            console.log(error);
          },
        });
      }
    });
  }

  resetPassword(): void {
    this.userService.resetPassword(this.resetPasswordModel).subscribe({
      next: (data) => {
        if (data['data'] === 'success') {
          this.router.navigate(['/get-started']);
          setTimeout(() => {
            // this._flashMessagesService.show(data['message'], { cssClass: 'alert-success', timeout: 5000 });
          }, 500);
        } else {
          this.router.navigate(['/get-started']);
          setTimeout(() => {
            // this._flashMessagesService.show(data['message'], { cssClass: 'alert-danger', timeout: 5000 });
          }, 500);
        }
      },
      error: (error) => {
        console.log('Failed to sign in. Something went wrong.');
        console.log(error);
      },
    });
  }
}
