<div class="front login container-fluid">
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <div class="container-fluid">
        <div class="row row-front">
          <!-- <flash-messages></flash-messages> -->
          <div
            class="col-lg-offset-4 col-md-4 col-md-offset-4 col-sm-offset-3 col-sm-6"
          >
            <div class="panel panel-default">
              <div class="panel-body">
                <!-- Login Form -->
                <h4 class="login-head">Login with your HappyMeter Account</h4>
                <form (ngSubmit)="doLogin()" #signInForm="ngForm">
                  <div class="form-group">
                    <label>E-mail address</label>
                    <input
                      type="email"
                      placeholder="Enter email"
                      class="form-control"
                      required
                      email
                      name="email"
                      [(ngModel)]="signinModel.email"
                      #loginemail="ngModel"
                    />
                    <!-- <div *ngIf="loginemail.invalid && (loginemail.dirty || loginemail.touched)" class="alert alert-danger">
                                          <ng-container *ngIf="loginemail.errors?.['required']">
                                              Email is required.
                                          </ng-container>
                                          <ng-container *ngIf="!loginemail.errors?.['required'] && loginemail.errors?.['email']">
                                              Invalid email address.
                                          </ng-container>
                                      </div> -->
                    <div
                      *ngIf="
                        loginemail.invalid &&
                        signinModel.email &&
                        signinModel.email.length > 0
                      "
                      class="alert alert-danger"
                    >
                      <ng-container *ngIf="loginemail.errors?.['required']">
                        Email is required.
                      </ng-container>
                      <ng-container
                        *ngIf="!loginemail.errors?.['required'] && loginemail.errors?.['email']"
                      >
                        Invalid email address.
                      </ng-container>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      class="form-control"
                      required
                      [(ngModel)]="signinModel.password"
                      name="password"
                      #password="ngModel"
                    />
                    <div
                      *ngIf="
                        password.invalid &&
                        signinModel.password &&
                        signinModel.password.length > 0
                      "
                      class="alert alert-danger"
                    >
                      <ng-container *ngIf="password.errors?.['required']">
                        Password is required.
                      </ng-container>
                    </div>
                  </div>
                  <div class="forgot-pass">
                    <a href="#" data-toggle="modal" data-target="#forgot"
                      >Forgot your Password?</a
                    >
                  </div>
                  <div align="center">
                    <button
                      type="submit"
                      class="btn btn-sm btn-primary btn-login"
                      [disabled]="!signInForm.form.valid"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
                <!-- /Login Form -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END row-->
    </div>
  </section>

  <!-- Modal start-->
  <div class="modal fade" id="forgot" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 class="modal-title">Forgot Password</h4>
        </div>
        <div class="modal-body">
          <form (ngSubmit)="forgotPassword()" #forgotPasswordForm="ngForm">
            <div class="form-group">
              <label
                >Please enter your e-mail address to receive password reset
                link:
              </label>
              <input
                type="email"
                placeholder="Enter email"
                class="form-control"
                required
                [(ngModel)]="forgotPasswordModel.email"
                name="email"
                #forgotpasswordemail="ngModel"
              />
            </div>
            <div align="center">
              <button
                type="submit"
                class="btn btn-sm btn-primary btn-login"
                [disabled]="!forgotPasswordForm.form.valid"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
