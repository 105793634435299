<div class="front mysurvey container-fluid">
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <!-- <flash-messages></flash-messages> -->
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body">
                <!--                                <ng-container *ngIf="surveys?.length > 0">
                                    <h3 class="mysurvey-head">Your Surveys</h3>
                                </ng-container>                                                                
                                <ng-container *ngIf="surveys?.length == 0">                                        
                                    <h3 class="mysurvey-head">No survey is assigned to you.</h3>
                                </ng-container>                                -->
                <h3 class="mysurvey-head">{{ title }}</h3>
                <div class="row">
                  <div
                    class="mysurvey01 col-md-4 col-sm-6"
                    *ngFor="let survey of surveys"
                  >
                    <!-- <p class="ms01">SURYEY ID: {{survey._id}}</p> -->
                    <p class="ms01">SURVEY ID: {{ survey.code }}</p>

                    <p class="ms02 text-primary">{{ survey.title }}</p>

                    <ng-container *ngIf="survey.expressions !== ''">
                      <!-- <p class="ms02 text-success">{{survey.expressions | parseEmoji}}</p> -->
                      <img
                        [src]="survey.expressions | parseEmoji"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis sanchit"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                      />
                    </ng-container>
                    <ng-container *ngIf="survey.expressions === ''">
                      <!-- <p class="ms02 text-success">{{survey.expressions | parseEmoji}}</p> -->
                      <img
                        src="../../../assets/imgs/emoji/0.png"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis sanchit"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                      />
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus === 'Not Started'">
                      <p class="ms02 text-danger">{{ survey.serveyStatus }}</p>
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus === 'Started'">
                      <p class="ms02 text-danger">{{ survey.serveyStatus }}</p>
                      <a
                        class="btn btn-sm btn-primary btn-survey"
                        *ngIf="survey.status === 'Not Started'"
                        href="javascript:void(0)"
                        (click)="startSurvey(survey._id)"
                        >Start Survey</a
                      >
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        *ngIf="survey.status === 'IN-PROGRESS'"
                        href="javascript:void(0)"
                        (click)="startSurvey(survey._id)"
                        >Resume Survey</a
                      >
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        *ngIf="
                          survey.status === 'IN-PROGRESS' ||
                          survey.status === 'Completed'
                        "
                        href="javascript:void(0)"
                        (click)="viewAnswers(survey._id)"
                        >View Answers</a
                      >
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus === 'Paused'">
                      <p class="ms02 text-danger">{{ survey.serveyStatus }}</p>
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        href="javascript:void(0)"
                        (click)="viewAnswers(survey._id)"
                        >View Answers</a
                      >
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus === 'In Review'">
                      <p class="ms02 text-danger">{{ survey.serveyStatus }}</p>
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        href="javascript:void(0)"
                        (click)="viewAnswers(survey._id)"
                        >View Answers</a
                      >
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus === 'Published'">
                      <p class="ms02 text-danger">{{ survey.serveyStatus }}</p>
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        href="javascript:void(0)"
                        (click)="viewAnswers(survey._id)"
                        >Published Answers</a
                      >
                      <!-- <a class="btn btn-sm btn-primary btn-survey" href="javascript:void(0)" (click)="publishedSurvey(survey._id)">Published Survey</a> -->
                    </ng-container>

                    <ng-container *ngIf="survey.serveyStatus !== 'Not Started'">
                      <a
                        class="btn btn-sm btn-primary btn-survey btn-min-width"
                        *ngIf="survey.status !== 'Not Started'"
                        href="javascript:void(0)"
                        (click)="metriccomparison(survey._id)"
                        >Metric Comparison</a
                      >
                    </ng-container>

                    <!-- <ng-container *ngIf="survey.status == 'IN-PROGRESS'"> -->

                    <!-- <ng-container *ngIf="count == 5"> -->
                    <!-- <ng-container *ngIf="survey.totalCount == survey.completeCount">
                                                <p class="ms02 text-danger">In Progress</p>
                                                <a href="#" data-toggle="modal" data-target="#submitModalDash" class="btn btn-sm btn-primary btn-survey" >Complete Survey
                                                </a>
                                            </ng-container>     -->

                    <!-- <ng-container *ngIf="count != 5"> -->
                    <!-- <ng-container *ngIf="survey.totalCount != survey.completeCount">
                                                <p class="ms02 text-danger">In Progress</p>
                                                <a class="btn btn-sm btn-primary btn-survey btn-min-width" href="javascript:void(0)" (click)="viewAnswers(survey._id)">View Answers</a>
                                                <a class="btn btn-sm btn-primary btn-survey btn-min-width" href="javascript:void(0)" (click)="startSurvey(survey._id)">Resume Survey</a>                                           
                                            </ng-container>  -->
                    <!-- </ng-container> -->

                    <!-- <ng-container *ngIf="survey.status == 'Not Started'">
                                            <p class="ms02 text-danger">Not Started</p>
                                            <a class="btn btn-sm btn-primary btn-survey" href="javascript:void(0)" (click)="startSurvey(survey._id)">Start Survey</a>
                                        </ng-container> -->
                    <!-- PUBLISHED SURVEY -->
                    <!-- <ng-container *ngIf="survey.status == 'Not Started'">
                                            <p class="ms02 text-danger">Not Started</p>
                                            <a class="btn btn-sm btn-primary btn-survey" href="javascript:void(0)" (click)="publishedSurvey(survey._id)">Published Survey</a>
                                        </ng-container> -->

                    <!-- <ng-container *ngIf="survey.status == 'COMPLETED'">
                                            <p class="ms02 text-success">Completed</p>

                                            <a class="btn btn-sm btn-primary btn-survey" href="javascript:void(0)" (click)="viewSurvey(survey._id)">View Answers</a>
                                        </ng-container>                                        -->
                  </div>
                </div>
              </div>
            </div>
            <!-- END panel-->
          </div>
        </div>
      </div>
      <!-- END row-->
    </div>
  </section>
  <div class="modal fade" id="submitModalDash" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 class="modal-title">Submit Survey</h4>
        </div>
        <div class="modal-body">
          <p>
            Great job, you've answered all the questions and are ready to submit
            the survey.
          </p>
          <button
            type="button"
            class="btn btn-sm btn-primary btn-about"
            (click)="submitSurvey()"
          >
            SUBMIT
            <img
              style="max-width: 30px; margin-left: 3px"
              src="assets/imgs/submit.png"
              alt="Submit icon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
