import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.guard';

import { FrontendComponent } from './frontend/frontend.component';
import { SignInSignUpComponent } from './frontend/signin-signup/signin-signup.component';
import { HomeComponent } from './frontend/home/home.component';
import { UserDashboardComponent } from './frontend/user-dashboard/user-dashboard.component';
import { UserProfileComponent } from './frontend/user-profile/user-profile.component';
import { SurveyCategoryComponent } from './frontend/survey-category/survey-category.component';
import { SurveySubcategoryComponent } from './frontend/survey-subcategory/survey-subcategory.component';
import { SurveyQuestionComponent } from './frontend/survey-question/survey-question.component';
import { ResetPasswordComponent } from './frontend/reset-password/reset-password.component';

import { BackendComponent } from './backend/backend.component';
import { DashboardComponent } from './backend/dashboard.component';
import { PageNotFoundComponent } from './page-not-found.component';

import { UserService } from './frontend/services/user.service';
import { SurveyService } from './frontend/services/survey.service';
import { ThankyouComponent } from './frontend/thankyou/thankyou.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core'; // Updated import
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ParseEmojiPipe } from './pipes/parse-emoji.pipe';
import { EmojiValuePipe } from './pipes/emoji-value.pipe';
import { ExpressionValue } from './pipes/expression';
import { ViewAnswerComponent } from './frontend/view-answer/view-answer.component';
import { PublishedServeyComponent } from './frontend/published-survey/published-survey.component';
import { MetricComparisonComponent } from './frontend/metric-comparison/metric-comparison.component';
import { ResponseAnalysisComponent } from './frontend/response-analysis/response-analysis.component';
import { ResponseAnalysisCatComponent } from './frontend/response-analysis-cat/response-analysis-cat.component';
import { ResponseAnalysisSurveyComponent } from './frontend/response-analysis-survey/response-analysis-survey.component';
import { DataLoadingInterceptor } from './frontend/services/dataloading.interceptor';
import { JwtInterceptor } from './frontend/services/jwt.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    FrontendComponent,
    SignInSignUpComponent,
    HomeComponent,
    UserDashboardComponent,
    UserProfileComponent,
    SurveyCategoryComponent,
    SurveySubcategoryComponent,
    SurveyQuestionComponent,
    BackendComponent,
    DashboardComponent,
    PageNotFoundComponent,
    ResetPasswordComponent,
    ThankyouComponent,
    ParseEmojiPipe,
    EmojiValuePipe,
    ViewAnswerComponent,
    PublishedServeyComponent,
    ExpressionValue,
    MetricComparisonComponent,
    ResponseAnalysisComponent,
    ResponseAnalysisCatComponent,
    ResponseAnalysisSurveyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule, // Updated import
    MatDatepickerModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  providers: [
    UserService,
    SurveyService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DataLoadingInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
