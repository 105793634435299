<div class="front priorities container-fluid">
  <!-- Main section-->
  <section class="first-question">
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body">
                <ng-container *ngIf="contentAvailable">
                  <div class="row">
                    <div class="form-group">
                      <div class="col-sm-12" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>

                            <!-- <ng-container *ngFor="let category of categories">
                                                            <li>
                                                                <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                    <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                                </a>
                                                            </li>
                                                        </ng-container> -->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Change Color -->
                  <h2 class="about-head">
                    How highly do you value the following topics, relative to
                    each other?
                  </h2>
                  <div
                    class="row row-priorities"
                    *ngFor="let category of categories"
                  >
                    <div id="{{ category.categoryId }}">
                      <div class="form-group">
                        <label
                          class="col-sm-4 col-xs-12 control-label text-success"
                        >
                          <a
                            [routerLink]="[
                              '/survey',
                              surveyId,
                              'category',
                              category.categoryId
                            ]"
                            style="color: #ef684a"
                          >
                            {{ category.title }}
                          </a>
                        </label>

                        <div class="col-lg-5 col-sm-8 col-xs-10">
                          <div class="form-group">
                            <mat-slider
                              min="0"
                              max="100"
                              step="1"
                              thumbLabel
                              [disabled]="surveyStatus"
                            >
                              <input
                                matSliderThumb
                                [(ngModel)]="category.satisfactionLevel"
                                (input)="updateSliderValue($event, category)"
                              />
                            </mat-slider>
                          </div>
                        </div>

                        <div
                          class="col-xs-3 text-right srv-cat-sz-nw nw-width-auto"
                        >
                          <div *ngIf="isValues === true">
                            <ng-container *ngIf="category.expressions !== ''">
                              <div
                                style="
                                  position: absolute;
                                  font-size: 16px;
                                  padding-top: 6px;
                                "
                              >
                                {{ category.expressions }}
                              </div>
                            </ng-container>
                            <ng-container *ngIf="category.expressions === ''">
                              <div
                                class="nadiv2"
                                style="
                                  position: absolute;
                                  font-size: 16px;
                                  padding-top: 6px;
                                  color: red;
                                "
                              >
                                N/A
                              </div>
                            </ng-container>
                          </div>
                          <ng-container *ngIf="category.expressions !== ''">
                            <img
                              [src]="category.expressions | parseEmoji"
                              style="max-width: 35px; vertical-align: top"
                              class="catemojis sanchit"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                              alt="Satisfaction emoji"
                            />
                          </ng-container>
                          <ng-container *ngIf="category.expressions === ''">
                            <img
                              src="assets/imgs/emoji_new/emo0.png"
                              style="max-width: 35px; vertical-align: top"
                              class="catemojis sanchit"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                              alt="Default emoji"
                            />
                          </ng-container>
                          <!--<a href="#" data-toggle="tooltip" data-placement="top" title="Completed" class="completed">
                                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                                </a>-->

                          <div class="submission question-submission cmnticn">
                            <a href="javascript:void(0)" class="comment">
                              <ng-container
                                *ngIf="category.comments === undefined"
                              >
                                <img
                                  class="commentimg"
                                  src="assets/imgs/comment.png"
                                  style="
                                    width: 36%;
                                    margin-top: -30px;
                                    margin-right: 23px !important;
                                  "
                                  alt="Comment"
                                />
                              </ng-container>
                              <!-- <ng-container *ngIf='category.comments == ""'>
                                                                <img class="commentimg" src="assets/imgs/comment.png" style="width:36%;margin-top: -30px;margin-right:45px;">
                                                            </ng-container> -->
                              <ng-container
                                *ngIf="category.comments !== undefined"
                              >
                                <ng-container *ngIf="category.comments === ''">
                                  <img
                                    class="commentimg"
                                    src="assets/imgs/comment.png"
                                    style="
                                      width: 36%;
                                      margin-top: -30px;
                                      margin-right: 23px !important;
                                    "
                                    alt="Comment"
                                  />
                                </ng-container>
                                <ng-container *ngIf="category.comments !== ''">
                                  <img
                                    class="commentimg"
                                    src="assets/imgs/chat.png"
                                    style="
                                      width: 36%;
                                      margin-top: -30px;
                                      margin-right: 23px !important;
                                    "
                                    alt="Chat"
                                  />
                                </ng-container>
                              </ng-container>
                              <div>
                                <ng-container *ngIf="surveyStatus === true">
                                  <!-- <textarea placeholder="Your Comment" rows="3" class="comment-area" (keypress)="msgComments($event, category)" disabled={{surveyStatus}}>{{category.comments}}</textarea> -->
                                  <textarea
                                    placeholder="Your Comment"
                                    rows="3"
                                    class="comment-area"
                                    (keypress)="msgComments($event, category)"
                                    >{{ category.comments }}</textarea
                                  >
                                  <button
                                    type="button"
                                    class="btn btn-success close-comment"
                                  >
                                    X
                                  </button>
                                </ng-container>
                                <ng-container *ngIf="surveyStatus === false">
                                  <textarea
                                    placeholder="Your Comment"
                                    rows="3"
                                    class="comment-area"
                                    (blur)="saveComments($event, category)"
                                    >{{ category.comments }}</textarea
                                  >
                                  <button
                                    type="button"
                                    class="btn btn-success save-comment"
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-success close-comment"
                                  >
                                    X
                                  </button>
                                </ng-container>
                              </div>
                            </a>
                          </div>

                          <a
                            [routerLink]="[
                              '/survey',
                              surveyId,
                              'category',
                              category.categoryId
                            ]"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Start Survey"
                            class="start-survey"
                          >
                            <img
                              src="assets/imgs/right.png"
                              class="arrow-img"
                              style="
                                max-width: 35px;
                                vertical-align: top;
                                margin-left: 10px;
                              "
                              alt="Right arrow"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="row row-about4">
                    <div class="form-group">
                      <div class="col-sm-6" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav1">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <!--                                                            <a routerLink="/about" style="padding-right:20px; border-right: 1px solid #a59090;">-->
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>

                            <!-- <ng-container *ngFor="let category of categories">
                                                         <ng-container *ngFor="let newcategory of newcategories">
                                                             <ng-container *ngIf="category.categoryId == newcategory.categoryId">
                                                                 <ng-container *ngIf="newcategory.status == 'completed'">
                                                                     <ng-container *ngIf="newcategory.title == 'Meaningful Work'">
                                                                         <li>
                                                                             <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                 <img src="assets/imgs/newMeaningful Work.png" title="{{newcategory.title}}">
                                                                             </a>
                                                                         </li>
                                                                     </ng-container>

                                                                     <ng-container *ngIf="newcategory.title == 'Compensation and Benefits'">
                                                                         <li>
                                                                             <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                 <img src="assets/imgs/newCB.png" title="{{newcategory.title}}">
                                                                             </a>
                                                                         </li>

                                                                     </ng-container>

                                                                     <ng-container *ngIf="newcategory.title == 'Career Future'">
                                                                         <li>
                                                                             <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                 <img src="assets/imgs/newCF.png" title="{{newcategory.title}}">
                                                                             </a>
                                                                         </li>

                                                                     </ng-container>

                                                                     <ng-container *ngIf="newcategory.title == 'Corporate Management'">
                                                                         <li>
                                                                             <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                 <img src="assets/imgs/newCM.png" title="{{newcategory.title}}">
                                                                             </a>
                                                                         </li>

                                                                     </ng-container>

                                                                     <ng-container *ngIf="newcategory.title == 'Environment'">
                                                                         <li>
                                                                             <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                 <img src="assets/imgs/newenv.png" title="{{newcategory.title}}">
                                                                             </a>
                                                                         </li>
                                                                     </ng-container>
                                                                 </ng-container>
                                                                 <ng-container *ngIf="newcategory.status == 'incompleted'">
                                                                     <li>
                                                                         <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                             <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                                         </a>
                                                                     </li>
                                                                 </ng-container>
                                                             </ng-container>
                                                         </ng-container>
                                                     </ng-container>-->
                          </ul>
                        </div>
                      </div>
                      <div class="col-sm-6" align="right">
                        <div
                          class="btn-group btn-group-lg"
                          role="group"
                          aria-label="Next Previous button group"
                        >
                          <button
                            type="button"
                            class="btn btn-default prev-btn"
                            disabled
                          >
                            &#171;&nbsp;Previous
                          </button>
                          <button
                            type="button"
                            class="btn btn-default next-btn"
                            (click)="moveToNextStep()"
                          >
                            Next&nbsp;&#187;
                          </button>
                        </div>
                        <ng-container *ngIf="surveyStatus == false">
                          <ng-container *ngIf="count == 5">
                            <button
                              type="button"
                              class="btn btn-sm btn-primary btn-about"
                              (click)="submitSurvey()"
                            >
                              SUBMIT
                              <img
                                style="max-width: 30px; margin-left: 3px"
                                src="assets/imgs/submit.png"
                              />
                            </button>
                          </ng-container>

                          <ng-container *ngIf="count != 5">
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#submitModalCat"
                              class="btn btn-sm btn-primary btn-about"
                              style="opacity: 0.7"
                              >SUBMIT
                              <img
                                style="max-width: 30px; margin-left: 3px"
                                src="assets/imgs/submit.png"
                              />
                            </a>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- END panel-->
        </div>
      </div>
    </div>
    <!-- END row-->
  </section>

  <!-- Modal start-->
  <div class="modal fade" id="submitModalCat" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 class="modal-title">Submit Survey</h4>
        </div>
        <div class="modal-body">
          <p>
            You have not yet answered all the questions in the survey.
            Submitting it now might provide incomplete or incorrect results.
            Would you like to submit the survey anyway?
          </p>
          <button
            type="button"
            class="btn btn-sm btn-primary btn-about"
            (click)="submitSurvey()"
          >
            SUBMIT NOW
            <img
              style="max-width: 30px; margin-left: 3px"
              src="assets/imgs/submit.png"
              alt="Submit icon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
