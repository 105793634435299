<div class="container">
  <div class="sev-met-title">
    <h2>Survey Results</h2>
    <p>{{ oname }} Satisfaction Survey 2019</p>
    <div class="showImp">
      Show Importance
      <input
        type="checkbox"
        name="simp"
        id="simp"
        class="simpcl chk"
        [checked]="true"
        (change)="showImportance($event)"
      />
    </div>
  </div>
  <div class="servey-part-out servey-part-out2 sev-met-table">
    <div class="servey-part">
      <table border="1" id="table_detail" align="center" cellpadding="10">
        <tr>
          <th>
            <button class="plusbtn" id="colAll" (click)="cALL($event)">
              btn</button
            >Career Value
          </th>
          <th colspan="2" class="cs2">{{ uname }}</th>
          <ng-container *ngIf="depFilter">
            <th colspan="2" class="cs2">{{ dname }}</th>
          </ng-container>
          <th colspan="2" class="cs2">{{ oname }}</th>
        </tr>
        <ng-container *ngIf="newcategories">
          <ng-container *ngFor="let sdt of newcategories">
            <tr>
              <td
                style="color: #ef684a; font-weight: bold; border-color: #9a9999"
              >
                <a
                  href="javascript:void(0)"
                  style="color: #ef684a"
                  (click)="responseanalysisSurvey(SId)"
                  >{{ sdt.title }}</a
                >
              </td>
              <td colspan="2" class="cs2 cs2emo">
                <img
                  [src]="sdt.expressions | parseEmoji"
                  style="max-width: 35px; vertical-align: top"
                  class="catemojis"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                />
              </td>
              <ng-container *ngIf="depFilter">
                <td colspan="2" class="cs2 cs2emo">
                  <img
                    [src]="sdt.expressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                  />
                </td>
              </ng-container>
              <td colspan="2" class="cs2 cs2emo">
                <img
                  [src]="sdt.expressions | parseEmoji"
                  style="max-width: 35px; vertical-align: top"
                  class="catemojis"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                />
              </td>
            </tr>
          </ng-container>
          <ng-container *ngFor="let dt of newcategories">
            <ng-container *ngIf="dt.mct && dt.mct.length > 0">
              <ng-container *ngFor="let cat of dt.mct">
                <tr>
                  <td>
                    <button
                      (click)="show_hide_row(cat._id)"
                      id="{{ cat._id }}"
                      class="plusbtn"
                    >
                      cat btn</button
                    ><a
                      href="javascript:void(0)"
                      (click)="responseanalysisCat(SId, cat._id)"
                      ><b>{{ cat.title }}</b></a
                    >
                  </td>
                  <td>
                    <img
                      [src]="cat.expressions | parseEmoji"
                      style="max-width: 35px; vertical-align: top"
                      class="catemojis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    />
                  </td>
                  <td class="simpc">
                    <mat-slider
                      [disabled]="true"
                      [min]="0"
                      [max]="100"
                      [(ngModel)]="cat.satisfactionLevel"
                    ></mat-slider>
                  </td>
                  <ng-container *ngIf="depFilter">
                    <td>
                      <img
                        [src]="cat.expressions | parseEmoji"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                      />
                    </td>
                    <td class="simpc">
                      <mat-slider
                        [disabled]="true"
                        [min]="0"
                        [max]="100"
                        [(ngModel)]="cat.satisfactionLevel"
                      ></mat-slider>
                    </td>
                  </ng-container>
                  <td>
                    <img
                      [src]="cat.expressions | parseEmoji"
                      style="max-width: 35px; vertical-align: top"
                      class="catemojis"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    />
                  </td>
                  <td class="simpc">
                    <mat-slider
                      [disabled]="true"
                      [min]="0"
                      [max]="100"
                      [(ngModel)]="cat.satisfactionLevel"
                    ></mat-slider>
                  </td>
                </tr>

                <!--if sub cat is there-->
                <ng-container *ngIf="cat.sub_category">
                  <ng-container *ngFor="let subcat of cat.sub_category">
                    <tr class="{{ cat._id }} show1">
                      <td>
                        <button
                          (click)="show_hide_row(subcat._id)"
                          id="{{ subcat._id }}"
                          class="plusbtn"
                        >
                          sub btn</button
                        ><a
                          href="javascript:void(0)"
                          (click)="responseanalysisCat(SId, subcat._id)"
                          ><strong>{{ subcat.title }}</strong></a
                        >
                      </td>
                      <td>
                        <img
                          [src]="subcat.expressions | parseEmoji"
                          style="max-width: 35px; vertical-align: top"
                          class="catemojis"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        />
                      </td>
                      <td class="simpc">
                        <mat-slider
                          [disabled]="true"
                          [min]="0"
                          [max]="100"
                          [(ngModel)]="subcat.satisfactionLevel"
                        ></mat-slider>
                      </td>
                      <ng-container *ngIf="depFilter">
                        <td>
                          <img
                            [src]="subcat.expressions | parseEmoji"
                            style="max-width: 35px; vertical-align: top"
                            class="catemojis"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          />
                        </td>
                        <td class="simpc">
                          <mat-slider
                            [disabled]="true"
                            [min]="0"
                            [max]="100"
                            [(ngModel)]="subcat.satisfactionLevel"
                          ></mat-slider>
                        </td>
                      </ng-container>
                      <td>
                        <img
                          [src]="subcat.expressions | parseEmoji"
                          style="max-width: 35px; vertical-align: top"
                          class="catemojis"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        />
                      </td>
                      <td class="simpc">
                        <mat-slider
                          [disabled]="true"
                          [min]="0"
                          [max]="100"
                          [(ngModel)]="subcat.satisfactionLevel"
                        ></mat-slider>
                      </td>
                    </tr>

                    <ng-container *ngFor="let ques of subcat.questions">
                      <tr class="{{ subcat._id }} {{ cat._id }} show1">
                        <td class="ques">
                          <a
                            href="javascript:void(0)"
                            (click)="responseanalysis(SId, ques._id)"
                            >{{ ques.content }}</a
                          >
                        </td>
                        <td>
                          <img
                            [src]="ques.expressions | emojiValue"
                            style="max-width: 35px; vertical-align: top"
                            class="catemojis"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          />
                        </td>
                        <td class="simpc">
                          <mat-slider
                            [disabled]="true"
                            [min]="0"
                            [max]="100"
                            [(ngModel)]="ques.satisfactionLevel"
                          ></mat-slider>
                        </td>
                        <ng-container *ngIf="depFilter">
                          <td>
                            <img
                              [src]="ques.expressions | parseEmoji"
                              style="max-width: 35px; vertical-align: top"
                              class="catemojis"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                            />
                          </td>
                          <td class="simpc">
                            <mat-slider
                              [disabled]="true"
                              [min]="0"
                              [max]="100"
                              [(ngModel)]="ques.satisfactionLevel"
                            ></mat-slider>
                          </td>
                        </ng-container>
                        <td>
                          <img
                            [src]="ques.expressions | parseEmoji"
                            style="max-width: 35px; vertical-align: top"
                            class="catemojis"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          />
                        </td>
                        <td class="simpc">
                          <mat-slider
                            [disabled]="true"
                            [min]="0"
                            [max]="100"
                            [(ngModel)]="ques.satisfactionLevel"
                          ></mat-slider>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <!--if sub cat is not there-->
                <ng-container *ngIf="!cat.sub_category">
                  <ng-container *ngFor="let ques of cat.questions">
                    <tr class="{{ cat._id }} show1">
                      <td class="ques">
                        <a
                          href="javascript:void(0)"
                          (click)="responseanalysis(SId, ques._id)"
                          >{{ ques.content }}</a
                        >
                      </td>
                      <td>
                        <img
                          [src]="ques.expressions | emojiValue"
                          style="max-width: 35px; vertical-align: top"
                          class="catemojis"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        />
                      </td>
                      <td class="simpc">
                        <mat-slider
                          [disabled]="true"
                          [min]="0"
                          [max]="100"
                          [(ngModel)]="ques.satisfactionLevel"
                        ></mat-slider>
                      </td>
                      <ng-container *ngIf="depFilter">
                        <td>
                          <img
                            [src]="ques.expressions | parseEmoji"
                            style="max-width: 35px; vertical-align: top"
                            class="catemojis"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          />
                        </td>
                        <td class="simpc">
                          <mat-slider
                            [disabled]="true"
                            [min]="0"
                            [max]="100"
                            [(ngModel)]="ques.satisfactionLevel"
                          ></mat-slider>
                        </td>
                      </ng-container>
                      <td>
                        <img
                          [src]="ques.expressions | parseEmoji"
                          style="max-width: 35px; vertical-align: top"
                          class="catemojis"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        />
                      </td>
                      <td class="simpc">
                        <mat-slider
                          [disabled]="true"
                          [min]="0"
                          [max]="100"
                          [(ngModel)]="ques.satisfactionLevel"
                        ></mat-slider>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </table>
      <div id="toTop"><i class="fa fa-arrow-up" aria-hidden="true"></i></div>
    </div>
  </div>
</div>
