import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUserStr = localStorage.getItem('currentUser');
    const currentUser = currentUserStr ? JSON.parse(currentUserStr) : null;
    if (currentUser && currentUser.accessToken) {
      request = request.clone({
        setHeaders: {
          accesstoken: `Bearer ${currentUser.accessToken}`,
        },
      });
    }

    // console.log('Before making api call : ', request);
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          // logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            // console.log('api call success :', event);
          }
        },
        error: (error) => {
          // logging the http response to browser's console in case of a failuer
          if (error instanceof HttpResponse) {
            // console.log('api call error :', error);
          }
        },
      })
    );
  }
}
