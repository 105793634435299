<div class="front login container-fluid">
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <!-- <flash-messages></flash-messages> -->
          <div class="col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body">
                <h4 class="login-head">Please enter your new Password</h4>
                <form (ngSubmit)="resetPassword()" #resetPasswordForm="ngForm">
                  <div class="form-group">
                    <label>New Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      class="form-control"
                      required
                      [(ngModel)]="resetPasswordModel.password"
                      name="password"
                      #resetpassword="ngModel"
                    />
                    <div
                      [hidden]="resetpassword.valid || resetpassword.pristine"
                      class="alert alert-danger"
                    >
                      Password is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control"
                      required
                      [(ngModel)]="resetPasswordModel.confPassword"
                      name="confpassword"
                      #confPassword="ngModel"
                    />
                    <div
                      [hidden]="confPassword.valid || confPassword.pristine"
                      class="alert alert-danger"
                    >
                      Confirm Password is required
                    </div>
                  </div>
                  <div align="center">
                    <button
                      type="submit"
                      class="btn btn-sm btn-primary btn-login"
                      [disabled]="!resetPasswordForm.form.valid"
                    >
                      Reset Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- END panel-->
          </div>
        </div>
      </div>
      <!-- END row-->
    </div>
  </section>
</div>
