import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './../services/user.service';
import { SurveyService } from './../services/survey.service';
import { Survey } from './../types/survey';
import { newCategory } from './../types/newcategory';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  standalone: false,
})
export class UserDashboardComponent implements OnInit {
  currentUserId: string;
  surveys: Survey[];
  notificationMessage: string;
  newcategories: newCategory[];
  surveyId: string;
  title: string;
  count: number;
  snackBarRef: any;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveys = [];
    this.notificationMessage = '';
    this.newcategories = [];
    this.surveyId = '';
    this.count = 0;
    this.title = '';
    this.snackBarRef = this.snackBar.open('Loading Data...');
  }

  ngOnInit(): void {
    this.loadSurveys();
  }

  loadSurveys(): void {
    this.notificationMessage = 'Loading...';
    this.surveyId = '';
    var data = { userId: this.currentUserId };
    this.surveyService.getUserBySurvey(data).subscribe(
      (data) => {
        if (data.surveys.length > 0) {
          this.notificationMessage = '';
          this.surveys = data.surveys;
          // data.surveys.forEach(survey => {
          //     this.surveys.push({
          //         _id: survey._id,
          //         title: survey.title,
          //         description: survey.description,
          //         status: "NOT-STARTED",
          //         expressions: ""
          //     });
          // });
          // this.surveyId = data.surveys[0]._id;
          // this.getSurveyStatus();
          // this.getSurveyNewChangeCategories(this.surveyId);
          this.getSurveyNewChangeCategories();
          this.title = 'Your Surveys';
          this.snackBarRef.dismiss();
        } else {
          this.title = 'No survey is assigned to you.';
          this.snackBarRef.dismiss();
          this.notificationMessage = 'No survey data found in the system.';
        }
      },
      (error) => {
        this.notificationMessage = 'Failed to load survey data.';
      }
    );
  }

  // loadSurveys(): void {
  //     this.notificationMessage = "Loading...";
  //     this.surveyId = "";
  //     this.surveyService
  //         .getSurveys()
  //         .subscribe(
  //         data => {
  //             if (data.surveys.length > 0) {
  //                 this.notificationMessage = "";

  //                 data.surveys.forEach(survey => {
  //                     this.surveys.push({
  //                         _id: survey._id,
  //                         title: survey.title,
  //                         description: survey.description,
  //                         status: "NOT-STARTED",
  //                         expressions: ""
  //                     });
  //                 });
  //                 this.surveyId = data.surveys[0]._id;
  //                 this.getSurveyStatus();
  //                 this.getSurveyNewChangeCategories(this.surveyId);
  //             } else {
  //                 this.notificationMessage = "No survey data found in the system.";
  //             }
  //         },
  //         error => {
  //             this.notificationMessage = "Failed to load survey data.";
  //         }
  //         )
  // }

  // getSurveyStatus(): void {
  //     this.surveyService
  //         .getSurveyStatus(this.currentUserId)
  //         .subscribe(
  //         data => {
  //             if (data.results.length > 0) {
  //                 data.results.forEach(response => {
  //                     this.surveys.forEach(thisSurvey => {
  //                         if (thisSurvey._id == response.surveyId) {
  //                             thisSurvey.status = response.status;
  //                             thisSurvey.expressions = response.expressions ? response.expressions : "";
  //                         }
  //                     });
  //                 });
  //             }
  //         },
  //         error => {
  //             console.log('Failed to get user survey status data.');
  //             console.log(error);
  //         }
  //         );
  // }

  startSurvey(surveyId: string): void {
    this.surveyService
      .startSurvey({
        userId: this.currentUserId,
        surveyId: surveyId,
      })
      .subscribe(
        (data) => {
          // Navigate to the main categories page.
          this.router.navigate(['/survey', surveyId]);
        },
        (error) => {
          console.log('Failed to update survey status.');
          console.log(error);
        }
      );
  }

  metriccomparison(surveyId: string): void {
    this.router.navigate(['/metriccomparison', surveyId]);
  }

  responseanalysis(surveyId: string): void {
    this.router.navigate(['/responseanalysis', surveyId]);
  }

  viewAnswers(surveyId: string): void {
    this.router.navigate(['/viewanswer', surveyId]);
  }
  viewSurvey(surveyId: string): void {
    this.router.navigate(['/survey', surveyId]);
  }
  publishedSurvey(surveyId: string): void {
    this.router.navigate(['/publishedservey', surveyId]);
  }
  getSurveyNewChangeCategories(): void {
    this.newcategories = [];

    let data = 'userId=' + this.currentUserId + '&surveyId=' + this.surveyId;

    this.surveyService.getNewChangeCategories(data).subscribe(
      (data) => {
        // data.data.forEach(category => {
        //     this.newcategories.push({
        //         userId: this.currentUserId,
        //         surveyId: category.surveyId,
        //         parentId: category.parentId,
        //         categoryId: category._id,
        //         description: category.description,
        //         title: category.title,
        //         status: category.status,
        //         satisfactionLevel: 0,
        //         expressions: ""
        //     });
        //     if (category.status == "completed") {
        //         this.count++;
        //     }
        // });

        this.surveys.forEach((surv) => {
          surv.completeCount = 0;
          data.data.forEach(
            (surveyStatus: {
              surveyId: string;
              categoryList: Array<{ status: string }>;
            }) => {
              if (surv._id === surveyStatus.surveyId) {
                var catlist = [];
                this.count = 0;

                catlist = surveyStatus.categoryList;
                var catlen = catlist.length;
                surv.totalCount = catlist.length;
                catlist.forEach((ctl) => {
                  if (ctl.status == 'completed') {
                    surv.completeCount++;
                  }
                });
              }
              // if (surveyStatus.status == "completed") {
              //     this.count++;
              // }
            }
          );
        });

        // this.getSurveyNewChangeCategoriesUserResponse();
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  submitSurvey(): void {
    this.surveyService
      .submitSurvey({
        userId: this.currentUserId,
        surveyId: this.surveyId,
      })
      .subscribe(
        (data) => {
          // Navigate to the dashboard.
          $('#submitModalDash').modal('hide');
          this.snackBar.open(
            'Thank you, your survey has been submitted, and we will share the results with you shortly.',
            '',
            {
              duration: 5000,
            }
          );
          // this.getSurveyStatus();
          // this.getSurveyNewChangeCategories(this.surveyId);
          //this.loadSurveys();
          //this.router.navigate(['/dashboard']);
          this.getSurveyNewChangeCategories();
        },
        (error) => {
          console.log('Failed to update survey status.');
          console.log(error);
        }
      );
  }
}
