import { Component, OnInit, AfterContentChecked, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  standalone: false,
})
export class FrontendComponent implements OnInit, AfterContentChecked {
  isLoggedIn = false;
  today: number;
  private document = inject(DOCUMENT);

  constructor(private userService: UserService, private router: Router) {
    this.today = Date.now();
  }

  ngAfterContentChecked(): void {
    this.isLoggedIn = this.userService.isLoggedIn();
  }

  ngOnInit(): void {}

  home(): void {
    this.toggleMenuIfVisible();
    this.router.navigate(['/']);
  }

  dashboard(): void {
    this.toggleMenuIfVisible();
    this.router.navigate(['/dashboard']);
  }

  login(): void {
    this.toggleMenuIfVisible();
    this.router.navigate(['/get-started']);
  }

  logout(): void {
    this.toggleMenuIfVisible();
    this.userService.signOut().subscribe({
      next: () => {
        this.userService.unSetUserLoggedIn();
        this.router.navigate(['/get-started']);
      },
      error: (error) => {
        console.error('Logout error:', error);
        // Handle error case if needed
      },
    });
  }

  /**
   * Helper method to toggle menu button if it's visible
   * Uses DOM API directly instead of jQuery
   */
  private toggleMenuIfVisible(): void {
    try {
      const menuBtn = this.document.getElementById('menubtn');
      if (menuBtn && window.getComputedStyle(menuBtn).display !== 'none') {
        // Create and dispatch a click event
        menuBtn.click();
      }
    } catch (error) {
      console.error('Error toggling menu:', error);
    }
  }
}
