import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../config/constants';
import { Observable, of } from 'rxjs';
import { userSurveyResponseType } from '../api-response-types/user-survey-response-type';

@Injectable()
export class SurveyService {
  constructor(private http: HttpClient) {}

  getNewChangeCategories(data: any): Observable<any> {
    const myheader = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http.post<any>(
      `${API_BASE_URL}api/users/check-category-status?${data}`,
      { headers: myheader }
    );
  }

  userSurveyData(): Observable<userSurveyResponseType> {
    return this.http.post<userSurveyResponseType>(
      `${API_BASE_URL}/api/user-survey`,
      ''
    );
  }

  getCategories(surveyId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/surveys/survey-categories/${surveyId}`
    );
  }

  getSubcategories(surveyId: string, categoryId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/surveys/survey-sub-categories/${surveyId}/${categoryId}`
    );
  }

  getQuestions(categoryId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/surveys/questions/${categoryId}`
    );
  }

  getCategory(categoryId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/surveys/survey-category/${categoryId}`
    );
  }

  getQuestion(
    surveyId: string,
    category: string,
    sectionId: string
  ): Observable<any> {
    return this.http.get<userSurveyResponseType>(
      `${API_BASE_URL}api/users/survey/${surveyId}/${category}/${sectionId}/1`
    );
  }

  getDashboardData(userId: any): Observable<any> {
    return this.http.get<any>(`${API_BASE_URL}api/users/dashboard/${userId}`);
  }

  saveSurveyData(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/users/save-survey-data`,
      data
    );
  }

  getSurveyData(surveyId: string, userId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/users/survey-data/${surveyId}/${userId}`
    );
  }

  getSurveys(): Observable<any> {
    return this.http.get<any>(`${API_BASE_URL}api/surveys/all`);
  }

  getSurveyStatus(userId: string): Observable<any> {
    return this.http.get<any>(
      `${API_BASE_URL}api/surveys/survey-status/${userId}`
    );
  }

  getSurveyCategoriesUserResponse(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/category-details`,
      data
    );
  }

  saveSurveyCategoriesUserResponse(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/save-category-data`,
      data
    );
  }

  submitSurvey(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/submit-survey`,
      data
    );
  }

  startSurvey(data: any): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/surveys/start-survey`, data);
  }

  getPrevCategory(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/previous-category`,
      data
    );
  }

  getNextCategory(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/next-category`,
      data
    );
  }

  saveSurveyQuestionUserResponse(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/users/save-question-response`,
      data
    );
  }

  getSurveyQuestionsUserResponse(filters: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/users/questions-response`,
      filters
    );
  }
  getViewAnswer(data: any): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/surveys/view-answers`, data);
  }
  getViewServeyDetails(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/view-survey-details`,
      data
    );
  }
  getPublishedServeyDetails(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/published-review`,
      data
    );
  }
  viewPublishedComment(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/view-published-comment`,
      data
    );
  }
  getUserBySurvey(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/user-survey-list`,
      data
    );
  }
  getSetting(): Observable<any> {
    return this.http.get<any>(`${API_BASE_URL}api/surveys/settings`);
  }
  getDepFilter(data: any): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/surveys/dep-filter`, data);
  }

  getMetricData(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/comparison-view`,
      data
    );
  }

  getUserDetails(data: any): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/surveys/user-details`, data);
  }

  getCloudData(data: any): Observable<any> {
    return this.http.post<any>(`${API_BASE_URL}api/surveys/word-cloud`, data);
  }

  getSurveyD(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/survey-avg-data`,
      data
    );
  }

  getComments(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/cloud-comments`,
      data
    );
  }

  getSurveyDC(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/survey-avg-data-cat`,
      data
    );
  }

  getCloudDataC(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/word-cloud-cat`,
      data
    );
  }

  getCommentsCat(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/cloud-comments-cat`,
      data
    );
  }

  getSurveyDS(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/survey-avg-data-survey`,
      data
    );
  }

  getCloudDataS(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/word-cloud-survey`,
      data
    );
  }

  getCommentsSurvey(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/cloud-comments-survey`,
      data
    );
  }

  getSurveyCategoryUserResponse(data: any): Observable<any> {
    return this.http.post<any>(
      `${API_BASE_URL}api/surveys/category-detail`,
      data
    );
  }
}
