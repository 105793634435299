import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'Happymeter';
  browserVersion: number | false = false;

  ngOnInit(): void {
    this.browserVersion = this.detectIE();

    // Optional: Log browser version for debugging
    if (this.browserVersion) {
      console.warn(
        `Internet Explorer/Edge version detected: ${this.browserVersion}`
      );
    }
  }

  /**
   * Detect Internet Explorer and Edge browser versions
   * @returns Version number of IE/Edge or false if not IE/Edge
   */
  detectIE(): number | false {
    // Use type-safe method for user agent detection
    const ua = window.navigator.userAgent;

    // Check for MSIE (Internet Explorer 10 and older)
    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    // Check for Trident (Internet Explorer 11)
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    // Check for Edge
    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // Not IE or Edge
    return false;
  }

  // Optional: Method to handle IE detection more proactively
  isIEBrowser(): boolean {
    return this.browserVersion !== false;
  }

  // Optional: Provide a warning or guidance for IE users
  getIECompatibilityMessage(): string | null {
    if (this.isIEBrowser()) {
      return 'Your browser is not fully supported. Please use a modern browser like Chrome, Firefox, or Edge for the best experience.';
    }
    return null;
  }
}
