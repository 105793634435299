export class UserProfile {
    constructor(
        public dob: any = new Date(),
        public gender: string = "",
        public educationLevel: string = "",
        public jobLevel: string = "",
        public fullname: string = "",
        public address: string = "",
        public phone: string = "",
        public state: string = "",
        public city: string = "",
        public email: string = "",
        public department: string = "",
        public subdepartment: string = "",
        public departmentName:string = "",
        public subdepartmentName:string = ""
    
    ) {}
}