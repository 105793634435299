<div class="front thank priorities container-fluid">
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body">
                <div class="row">
                  <div class="form-group">
                    <div class="col-sm-12" align="left">
                      <div class="container-fluid">
                        <ul class="footer-nav">
                          <li class="active">
                            <a href="#"><img src="assets/imgs/home.png" /></a>
                          </li>
                          <li>
                            <a href="#"><img src="assets/img/user.png" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row row-thank">
                  <div class="col-sm-8 col-sm-offset-2">
                    <h2 class="thank-head">Thank You</h2>
                    <p class="thank-text">
                      Thank you for Submitting your Survey. Based on your
                      survey, you can find Analytic reports for each category
                      below:
                    </p>
                  </div>
                </div>
                <div class="row"></div>
                <hr />
                <div class="row row-about4">
                  <div class="form-group">
                    <div class="col-sm-8" align="left">
                      <div class="container-fluid">
                        <ul class="footer-nav1">
                          <li class="active">
                            <a href="#"><img src="assets/imgs/home.png" /></a>
                          </li>
                          <li>
                            <a href="#"><img src="assets/imgs/user.png" /></a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END panel-->
        </div>
      </div>
    </div>
    <!-- END row-->
  </section>
</div>
