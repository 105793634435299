import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';

@Injectable()
export class DataLoadingInterceptor implements HttpInterceptor {
  snackBarRef: any;

  constructor(public snackBar: MatSnackBar) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      this.snackBar.open('Data not loaded. Please Refresh Page', '', {
        duration: 8000,
      });
      return throwError(
        () => new HttpErrorResponse({ error: 'Internet is required.' })
      );
    } else {
      return next.handle(request).pipe(
        tap({
          next: (event) => {
            if (event instanceof HttpResponse) {
              if (event.status === 502 || event.status === 503) {
                this.snackBar.open('Data not loaded. Please Refresh Page', '', {
                  duration: 8000,
                });
              }
            }
          },
          error: (error) => {
            console.log('error');
            console.log(error);
            if (error instanceof HttpResponse) {
              if (error.status === 502 || error.status === 503) {
                this.snackBar.open('Data not loaded. Please Refresh Page', '', {
                  duration: 8000,
                });
              }
            }
          },
        })
      );
    }
  }
}
