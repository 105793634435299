import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignInSignUpComponent } from './signin-signup/signin-signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { SurveyCategoryComponent } from './survey-category/survey-category.component';
import { SurveySubcategoryComponent } from './survey-subcategory/survey-subcategory.component';
import { SurveyQuestionComponent } from './survey-question/survey-question.component';
import { AuthGuard } from './../auth.guard';
import { ViewAnswerComponent } from './view-answer/view-answer.component';
import { PublishedServeyComponent } from './published-survey/published-survey.component';
import { MetricComparisonComponent } from './metric-comparison/metric-comparison.component';
import { ResponseAnalysisComponent } from './response-analysis/response-analysis.component';
import { ResponseAnalysisCatComponent } from './response-analysis-cat/response-analysis-cat.component';
import { ResponseAnalysisSurveyComponent } from './response-analysis-survey/response-analysis-survey.component';
export const FRONTEND_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'get-started',
    component: SignInSignUpComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: UserDashboardComponent,
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    component: UserProfileComponent,
  },
  {
    path: 'survey/:surveyId',
    canActivate: [AuthGuard],
    component: SurveyCategoryComponent,
  },
  {
    path: 'survey/:surveyId/category/:categoryId',
    canActivate: [AuthGuard],
    component: SurveySubcategoryComponent,
  },
  {
    path: 'survey/:surveyId/category/:categoryId/subcategory/:subCategoryId',
    canActivate: [AuthGuard],
    component: SurveyQuestionComponent,
  },
  {
    path: 'verify-email/:token',
    component: SignInSignUpComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'thankyou',
    canActivate: [AuthGuard],
    component: ThankyouComponent,
  },
  {
    path: 'publishedservey/:surveyId',
    canActivate: [AuthGuard],
    component: PublishedServeyComponent,
  },
  {
    path: 'viewanswer/:surveyId',
    canActivate: [AuthGuard],
    component: ViewAnswerComponent,
  },
  {
    path: 'metriccomparison/:surveyId',
    canActivate: [AuthGuard],
    component: MetricComparisonComponent,
  },
  {
    path: 'responseanalysis/:surveyId/:quesId',
    canActivate: [AuthGuard],
    component: ResponseAnalysisComponent,
  },
  {
    path: 'responseanalysiscat/:surveyId/:catId',
    canActivate: [AuthGuard],
    component: ResponseAnalysisCatComponent,
  },
  {
    path: 'responseanalysissurvey/:surveyId',
    canActivate: [AuthGuard],
    component: ResponseAnalysisSurveyComponent,
  },
];
