import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expression',
  standalone: false,
})
export class ExpressionValue implements PipeTransform {
  transform(expressions: string): any {
    let expressionScore = 0;
    if (expressions === 'emo10') {
      return (expressionScore = 100.0);
    } else if (expressions === 'emo9') {
      return (expressionScore = 87.5);
    } else if (expressions === 'emo8') {
      return (expressionScore = 75.0);
    } else if (expressions === 'emo7') {
      return (expressionScore = 62.5);
    } else if (expressions === 'emo5') {
      return (expressionScore = 50.0);
    } else if (expressions === 'emo4') {
      return (expressionScore = 38.5);
    } else if (expressions === 'emo3') {
      return (expressionScore = 25.0);
    } else if (expressions === 'emo2') {
      return (expressionScore = 12.5);
    } else if (expressions === 'emo1') {
      return (expressionScore = 1.0);
    } else {
      return (expressionScore = 0);
    }
  }
}
