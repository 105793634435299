export class ForgotPassword {
    
    public email : string;    

    constructor() {
        this.email = '';        
    }

    reset(): void {        
        this.email = '';        
    }
}