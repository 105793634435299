import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfile } from './user-profile';
import { UserService } from './../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  standalone: false,
})
export class UserProfileComponent implements OnInit {
  userProfile: UserProfile;
  currentUserId: string | null;
  maleImgSrc: string;
  femaleImgSrc: string;
  yearOptions: string[];
  educationLevelOptions: string[];
  jobLevelOptions: string[];
  states: any[];
  cities: any[];

  constructor(
    private userService: UserService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.userProfile = new UserProfile();
    this.maleImgSrc = 'assets/imgs/male.png';
    this.femaleImgSrc = 'assets/imgs/female.png';
    this.states = [];
    this.cities = [];
    this.yearOptions = [];
    this.setYearOptions();
    this.educationLevelOptions = [
      'Under Graduate',
      'Graduate',
      'Post Graduate',
      'Doctorate',
    ];
    this.jobLevelOptions = ['Beginner', 'Intermediate', 'Senior', 'Manager'];
  }

  private setYearOptions(): void {
    let year: number;
    for (year = 2010; year >= 1900; year--) {
      this.yearOptions.push(year.toString());
    }
  }

  ngOnInit(): void {
    this.loadCurrentUser();
  }

  loadCurrentUser(): void {
    if (this.currentUserId) {
      this.userService.getUserById(this.currentUserId).subscribe({
        next: (data) => {
          this.userProfile.dob =
            data.data && data.data.dob ? data.data.dob : '';
          this.userProfile.educationLevel =
            data.data && data.data.educationLevel
              ? data.data.educationLevel
              : '';
          this.userProfile.jobLevel =
            data.data && data.data.jobLevel ? data.data.jobLevel : '';

          if (this.userProfile.gender !== '') {
            //this.setGenderImgs();
          }
        },
        error: (error) => {
          // Show notification message (using Snackbar component).
          this.snackBar.open(
            'Failed to load current profile data. Please reload the page.',
            '',
            {
              duration: 5000,
            }
          );
        },
      });
    }
  }

  save(): void {
    // this.userProfile.dob=moment(this.userProfile.dob).format('M/D/YYYY');
    if (this.currentUserId) {
      this.userService
        .userAboutUpdate(this.currentUserId, this.userProfile)
        .subscribe({
          next: (data: any) => {
            if (data['data'] === 'success') {
              // Mark this user profile as completed.
              this.userService.setProfileCompleted('true');
              // Show notification message (using Snackbar component).
              this.snackBar.open('Profile data updated successfully.', '', {
                duration: 5000,
              });
            } else {
              // Show notification message (using Snackbar component).
              this.snackBar.open(
                'Failed to update profile data. Please try again.',
                '',
                {
                  duration: 5000,
                }
              );
            }
          },
          error: (error) => {
            // Show notification message (using Snackbar component).
            this.snackBar.open(
              error['error']?.message || 'An error occurred',
              '',
              {
                duration: 5000,
              }
            );
          },
        });
    }

    // Navigate Dashboard
    this.router.navigate(['/dashboard']);
  }

  // Skip will navigate user to the dashboard page.
  skip(): void {
    this.router.navigate(['/dashboard']);
  }
}
