import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SurveyService } from './../services/survey.service';
import { UserService } from './../services/user.service';

declare var $: any;

@Component({
  selector: 'response-analysis',
  templateUrl: './response-analysis-survey.component.html',
  styleUrls: ['./response-analysis-survey.component.css'],
  standalone: false,
})
export class ResponseAnalysisSurveyComponent implements OnInit {
  word_list: any;
  survey_data: any;
  survey_comments: any;
  surveyId: string;
  currentUserId: string;
  uname: any;
  dname: any;
  oname: any;
  depFilter: any;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.word_list = [];
    this.survey_data = [];
    this.survey_comments = [];
    this.surveyId = '';
    this.currentUserId = this.userService.getCurrentUserId();
    this.uname = '';
    this.dname = '';
    this.oname = '';
    this.depFilter = true;
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['surveyId'] !== undefined) {
        this.surveyId = params['surveyId'];
        this.getWordCloud();
        this.getSurveyAvgData();
        this.getUserDetails();
        this.getDepFilter();
      }
    });
  }

  getWordCloud(): void {
    var data = { surveyId: this.surveyId };
    this.surveyService.getCloudDataS(data).subscribe(
      (data) => {
        if (data.data.length > 0) {
          this.word_list = data.data;
          $('#my_favorite_latin_words').jQCloud(this.word_list);
        } else {
          this.word_list = null;
        }
      },
      (error) => {
        this.word_list = null;
      }
    );
  }

  getSurveyAvgData(): void {
    var data = { surveyId: this.surveyId, userId: this.currentUserId };
    this.surveyService.getSurveyDS(data).subscribe(
      (data) => {
        if (data.data.length > 0) {
          this.survey_data = data.data;
          setTimeout(function () {
            $('.mat-slider-thumb-container').css('display', 'none');
            $('.mat-slider-track-background').css('transform', 'none');
            $('.mat-slider-track-background').css('-webkit-transform', 'none');
            $('.mat-slider-track-background').css('-moz-transform', 'none');
          }, 200);
        } else {
          this.survey_data = null;
        }
      },
      (error) => {
        this.survey_data = null;
      }
    );
  }

  getUserDetails(): void {
    var data = { userId: this.currentUserId };
    this.surveyService.getUserDetails(data).subscribe(
      (data) => {
        if (data) {
          this.uname = data.name;
          this.dname = data.dname;
          this.oname = data.oname;
        } else {
          this.uname = 'Yours';
          this.dname = 'Department';
          this.oname = 'Organization';
        }
      },
      (error) => {
        this.uname = 'Yours';
        this.dname = 'Department';
        this.oname = 'Organization';
      }
    );
  }

  getComments(): void {
    var data = { surveyId: this.surveyId };
    this.surveyService.getCommentsSurvey(data).subscribe(
      (data) => {
        if (data.data.length > 0) {
          this.survey_comments = data.data;
          $('#commentModel').modal('show');
        } else {
          this.survey_comments = null;
        }
      },
      (error) => {
        this.survey_comments = null;
      }
    );
  }

  backtometric(): void {
    this.router.navigate(['/metriccomparison', this.surveyId]);
  }

  getDepFilter(): void {
    var data = { userId: this.currentUserId, surveyId: this.surveyId };
    this.surveyService.getDepFilter(data).subscribe(
      (data) => {
        if (data) {
          this.depFilter = data.data;
        } else {
          this.depFilter = 'true';
        }
      },
      (error) => {
        this.depFilter = 'true';
      }
    );
  }
}
