const url = window.location.hostname;
let new_url;
new_url = window.location.protocol + '//' + url + '/';
// if (url === 'happymeter.com') {
// new_url = 'http://' + 'happymeter.com' + '/';
// new_url = 'http://' + 'admin.happymeter.com' + '/';
// } else {
//   new_url = 'http://localhost:3000' + '/';
// }

export const API_BASE_URL = new_url;
export const OPTIONAL = 25;
