import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import components
import { FrontendComponent } from './frontend/frontend.component';
import { BackendComponent } from './backend/backend.component';

//Import route configurations
import { BACKEND_ROUTES } from './backend/routes';
import { FRONTEND_ROUTES } from './frontend/routes';
import { PageNotFoundComponent } from './page-not-found.component';

// Define application routes
const routes: Routes = [
  {
    path: '',
    component: FrontendComponent,
    children: FRONTEND_ROUTES,
  },
  {
    path: 'admin', // Consider adding a prefix for backend routes
    component: BackendComponent,
    children: BACKEND_ROUTES,
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**', // Wildcard route for handling 404
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Enable best practices for routing
      scrollPositionRestoration: 'enabled', // Scroll to top on navigation
      anchorScrolling: 'enabled',
      useHash: false, // Use HTML5 pushState for cleaner URLs
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
