import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css'],
  standalone: false,
})
export class ThankyouComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
