<div class="container viewanswerpage">
  <div class="servey-part-out servey-part-out2">
    <div class="servey-part">
      <select
        class="form-control"
        style="width: 30%"
        (change)="onOptionSelected($any($event.target).value)"
      >
        <option value="own">Own</option>
        <option value="org">Organization</option>
        <option value="dep">Department</option>
      </select>
      <br />
      <h1>
        <span class="c1">Career Value</span>
        <span class="c2">Satisfaction</span>
        <span class="c3" style="margin-top: 0px !important">Weight</span>
      </h1>
      <h2>
        <span class="c1">{{ SurveyTitle.title }}</span>
        <span class="c2"
          ><img
            [src]="SurveyTitle.expressions | parseEmoji"
            style="max-width: 35px; vertical-align: top"
            class="catemojis sanchit"
            data-toggle="tooltip"
            data-placement="top"
            title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
            alt="Satisfaction emoji"
          />
          <!--                    <small>{{SurveyTitle.expressions}}</small> -->
        </span>
      </h2>
      <ng-container *ngIf="newcategories">
        <div class="servey-one" *ngFor="let cat of newcategories">
          <h3>
            <span class="c1">{{ cat.title }}</span>
            <span class="c2">
              <img
                [src]="cat.expressions | parseEmoji"
                style="max-width: 35px; vertical-align: top"
                class="catemojis sanchit"
                data-toggle="tooltip"
                data-placement="top"
                title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                alt="Category emoji"
              />
              <!--                        <small *ngIf="cat.expressions">{{cat.expressions}}</small>
                                                    <small *ngIf="!cat.expressions">-</small>-->
            </span>
            <!--                    <span class="c3"  *ngIf="cat.satisfactionLevel">{{cat.satisfactionLevel}}</span>
                                            <span class="c3"  *ngIf="!cat.satisfactionLevel">-</span>-->
            <span class="c3" *ngIf="cat.satisfactionLevel"
              ><mat-slider
                min="0"
                max="100"
                thumbLabel
                [disabled]="true"
                [(ngModel)]="cat.satisfactionLevel"
              ></mat-slider
            ></span>
            <span class="c3" *ngIf="!cat.satisfactionLevel"
              ><mat-slider
                min="0"
                max="100"
                thumbLabel
                [disabled]="true"
                value="0"
              ></mat-slider
            ></span>
            <span class="c4" *ngIf="surStatus === 'Published'">
              <a (click)="openModel('catComment', cat)">
                <ng-container *ngIf="$any(cat).comments">
                  <img
                    class="commentimg"
                    src="assets/imgs/chat.png"
                    style="width: 36%; margin-right: 23px !important"
                    alt="Chat"
                  />
                </ng-container>
                <ng-container *ngIf="!$any(cat).comments">
                  <img
                    class="commentimg"
                    src="assets/imgs/comment.png"
                    style="width: 36%; margin-right: 23px !important"
                    alt="Comment"
                  />
                </ng-container>
              </a>
            </span>
          </h3>
          <h4 *ngIf="$any(cat).sub_category">
            <p *ngFor="let subcat of $any(cat).sub_category">
              <span>
                <strong class="c1">
                  {{ subcat.title }}
                </strong>
                <span class="c2">
                  <img
                    [src]="subcat.expressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis sanchit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    alt="Subcategory emoji"
                  />
                  <!--                                <small  *ngIf="subcat.expressions">{{subcat.expressions}}</small>
                                                                    <small *ngIf="!subcat.expressions">-</small>-->
                </span>
                <!--                            <span class="c3" *ngIf="subcat.satisfactionLevel">{{subcat.satisfactionLevel}}</span>
                                                            <span class="c3" *ngIf="!subcat.satisfactionLevel">-</span>-->
                <span class="c3" *ngIf="subcat.satisfactionLevel"
                  ><mat-slider
                    min="0"
                    max="100"
                    thumbLabel
                    [disabled]="true"
                    [(ngModel)]="subcat.satisfactionLevel"
                  ></mat-slider
                ></span>
                <span class="c3" *ngIf="!subcat.satisfactionLevel"
                  ><mat-slider
                    min="0"
                    max="100"
                    thumbLabel
                    [disabled]="true"
                    value="0"
                  ></mat-slider
                ></span>
                <span class="c4" *ngIf="surStatus === 'Published'">
                  <a (click)="openModel('subCatComment', subcat)">
                    <ng-container *ngIf="subcat.comments">
                      <img
                        class="commentimg"
                        src="assets/imgs/chat.png"
                        style="width: 36%; margin-right: 23px !important"
                        alt="Chat"
                      />
                    </ng-container>
                    <ng-container *ngIf="!subcat.comments">
                      <img
                        class="commentimg"
                        src="assets/imgs/comment.png"
                        style="width: 36%; margin-right: 23px !important"
                        alt="Comment"
                      />
                    </ng-container>
                  </a>
                </span>
                <p style="color: gray" *ngFor="let ques of subcat.questions">
                  <span class="c1">{{ ques.content }}</span>
                  <span class="c2">
                    <ng-container *ngIf="viewVal === 'own'">
                      <img
                        [src]="ques.expressions | emojiValue"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis sanchit"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        alt="Question emoji"
                      />
                    </ng-container>
                    <ng-container *ngIf="viewVal === 'org'">
                      <img
                        [src]="ques.expressions | parseEmoji"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis sanchit"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        alt="Question emoji"
                      />
                    </ng-container>
                    <ng-container *ngIf="viewVal === 'dep'">
                      <img
                        [src]="ques.expressions | parseEmoji"
                        style="max-width: 35px; vertical-align: top"
                        class="catemojis sanchit"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                        alt="Question emoji"
                      />
                    </ng-container>
                    <!--                                    <small *ngIf="ques.expressions">{{ques.expressions|expression| number: '.2-2'}}</small>
                                                                            <small *ngIf="!ques.expressions">-</small>-->
                  </span>
                  <!--                                <span class="c3" *ngIf="ques.satisfactionLevel">{{ques.satisfactionLevel}}</span>
                                                                    <span class="c3" *ngIf="!ques.satisfactionLevel">-</span>-->
                  <span class="c3" *ngIf="ques.satisfactionLevel"
                    ><mat-slider
                      min="0"
                      max="100"
                      thumbLabel
                      [disabled]="true"
                      [(ngModel)]="ques.satisfactionLevel"
                    ></mat-slider
                  ></span>
                  <span class="c3" *ngIf="!ques.satisfactionLevel"
                    ><mat-slider
                      min="0"
                      max="100"
                      thumbLabel
                      [disabled]="true"
                      value="0"
                    ></mat-slider
                  ></span>
                  <span class="c4" *ngIf="surStatus === 'Published'">
                    <a (click)="openModel('subCatQuestionComment', ques)">
                      <ng-container *ngIf="ques.comments">
                        <img
                          class="commentimg"
                          src="assets/imgs/chat.png"
                          style="width: 36%; margin-right: 23px !important"
                          alt="Chat"
                        />
                      </ng-container>
                      <ng-container *ngIf="!ques.comments">
                        <img
                          class="commentimg"
                          src="assets/imgs/comment.png"
                          style="width: 36%; margin-right: 23px !important"
                          alt="Comment"
                        />
                      </ng-container>
                    </a>
                  </span>
                </p>
              </span>
            </p>
          </h4>
          <h4 *ngIf="!$any(cat).sub_category">
            <p style="color: gray" *ngFor="let ques of $any(cat).questions">
              <span class="c1">{{ ques.content }}</span>
              <span class="c2">
                <ng-container *ngIf="viewVal === 'own'">
                  <img
                    [src]="ques.expressions | emojiValue"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis sanchit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    alt="Question emoji"
                  />
                </ng-container>
                <ng-container *ngIf="viewVal === 'org'">
                  <img
                    [src]="ques.expressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis sanchit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    alt="Question emoji"
                  />
                </ng-container>
                <ng-container *ngIf="viewVal === 'dep'">
                  <img
                    [src]="ques.expressions | parseEmoji"
                    style="max-width: 35px; vertical-align: top"
                    class="catemojis sanchit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                    alt="Question emoji"
                  />
                </ng-container>
                <!--                            <small *ngIf="ques.expressions">{{ques.expressions | expression | number: '.2-2'}}</small>
                                                            <small *ngIf="!ques.expressions">-</small>-->
              </span>
              <!--                        <span class="c3" *ngIf="ques.satisfactionLevel">{{ques.satisfactionLevel}}</span>
                                                    <span class="c3" *ngIf="!ques.satisfactionLevel">-</span>-->
              <span class="c3" *ngIf="ques.satisfactionLevel"
                ><mat-slider
                  min="0"
                  max="100"
                  thumbLabel
                  [disabled]="true"
                  [(ngModel)]="ques.satisfactionLevel"
                ></mat-slider
              ></span>
              <span class="c3" *ngIf="!ques.satisfactionLevel"
                ><mat-slider
                  min="0"
                  max="100"
                  thumbLabel
                  [disabled]="true"
                  value="0"
                ></mat-slider
              ></span>
              <span class="c4" *ngIf="surStatus === 'Published'">
                <a (click)="openModel('catQuestionComment', ques)">
                  <ng-container *ngIf="ques.comments">
                    <img
                      class="commentimg"
                      src="assets/imgs/chat.png"
                      style="width: 36%; margin-right: 23px !important"
                      alt="Chat"
                    />
                  </ng-container>
                  <ng-container *ngIf="!ques.comments">
                    <img
                      class="commentimg"
                      src="assets/imgs/comment.png"
                      style="width: 36%; margin-right: 23px !important"
                      alt="Comment"
                    />
                  </ng-container>
                </a>
              </span>
            </p>
          </h4>
        </div>
      </ng-container>
      <ng-container *ngIf="!newcategories">
        <div class="error">Not enough user for department view.</div>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal fade" id="publishedModel" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
        <h4 class="modal-title">Published Result</h4>
      </div>
      <div class="modal-body">
        <table class="table">
          <thead>
            <tr>
              <th>User Comment</th>
              <th>Manager Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <textarea disabled style="width: 100%">{{
                  PRData.comments
                }}</textarea>
              </td>
              <td>
                <textarea disabled style="width: 100%">{{
                  PRData.managerComment
                }}</textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          type="button"
          class="btn btn-sm btn-primary btn-about"
          data-dismiss="modal"
        >
          Close
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</div>
