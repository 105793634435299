<div class="front priorities container-fluid">
  <div
    class="progress"
    data-toggle="tooltip"
    data-placement="bottom"
    title="X of X answered"
  >
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuenow="2"
      aria-valuemin="1"
      aria-valuemax="100"
      style="width: 20%"
    ></div>
  </div>
  <!-- Main section-->
  <section>
    <!-- Page content-->
    <div class="content-wrapper">
      <!-- START row-->
      <div class="container-fluid">
        <div class="row row-front">
          <div class="col-md-10 col-md-offset-1">
            <!-- START panel-->
            <div class="panel panel-default">
              <div class="panel-body queBodyClass">
                <ng-container *ngIf="contentAvailable">
                  <div class="row">
                    <div class="form-group form-group-padd">
                      <div class="col-sm-12" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>
                            <li>
                              <a
                                [routerLink]="['/survey', surveyId]"
                                style="padding-right: 10px"
                              >
                                <img
                                  src="assets/imgs/category.png"
                                  title="Back to Main Categories"
                                  alt="Back to Main Categories"
                                />
                              </a>
                            </li>

                            <li>
                              <ng-container
                                *ngIf="categoryTitle === 'Meaningful Work'"
                              >
                                <a [routerLink]="['/survey', surveyId]"
                                  ><img
                                    src="assets/imgs/icon-9000.png"
                                    title="Level Up"
                                    alt="Level Up"
                                /></a>
                              </ng-container>
                              <ng-container
                                *ngIf="categoryTitle !== 'Meaningful Work'"
                              >
                                <a
                                  [routerLink]="[
                                    '/survey',
                                    surveyId,
                                    'category',
                                    categoryId
                                  ]"
                                  ><img
                                    src="assets/imgs/icon-9000.png"
                                    title="Level Up"
                                    alt="Level Up"
                                /></a>
                              </ng-container>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row title-bar-row title-bar-row-qus">
                    <!-- <div class="col-sm-1">
                                                                               
                                          </div> -->
                    <div
                      class="col-sm-9"
                      style="margin-right: -28px; margin-top: -10px"
                    >
                      <a
                        [routerLink]="['/survey', surveyId]"
                        style="
                          padding-right: 10px;
                          border-right: 1px solid #a59090;
                        "
                      >
                        <img
                          src="assets/imgs/category.png"
                          title="Back to Main Categories"
                          alt="Back to Main Categories"
                        />
                      </a>
                      <ng-container *ngIf="categoryTitle === 'Meaningful Work'">
                        <a [routerLink]="['/survey', surveyId]"
                          ><img
                            src="assets/imgs/icon-9000.png"
                            title="Level Up"
                            alt="Level Up"
                            style="margin-top: -10px"
                        /></a>
                      </ng-container>
                      <ng-container *ngIf="categoryTitle !== 'Meaningful Work'">
                        <a
                          [routerLink]="[
                            '/survey',
                            surveyId,
                            'category',
                            categoryId
                          ]"
                          ><img
                            src="assets/imgs/icon-9000.png"
                            title="Level Up"
                            alt="Level Up"
                            style="margin-top: -10px"
                        /></a>
                      </ng-container>
                      <h2 class="about-head bnps-head">
                        <ng-container
                          *ngIf="categoryTitle === 'Meaningful Work'"
                        >
                          <a
                            style="color: #656565; text-decoration: none"
                            [routerLink]="['/survey', surveyId]"
                            >{{ categoryTitle }}</a
                          >
                        </ng-container>
                        <ng-container
                          *ngIf="categoryTitle !== 'Meaningful Work'"
                        >
                          <a
                            style="color: #656565; text-decoration: none"
                            [routerLink]="[
                              '/survey',
                              surveyId,
                              'category',
                              categoryId
                            ]"
                            >{{ categoryTitle }}</a
                          >
                        </ng-container>
                      </h2>
                    </div>
                    <div
                      class="col-xs-3 text-right pad-right0 ptnew"
                      *ngFor="let subCategory of subCategories"
                    >
                      <!-- <div class="absVal" style="position: static;font-size: 20px;float: left;padding: 4px 10px 0 0;">{{subCategory.expressions}}</div> -->
                      <ng-container *ngIf="subCategory.expressions !== ''">
                        <img
                          [src]="subCategory.expressions | parseEmoji"
                          style="max-width: 32px; vertical-align: top"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          alt="Satisfaction emoji"
                        />
                      </ng-container>
                      <ng-container *ngIf="subCategory.expressions === ''">
                        <img
                          src="assets/imgs/emoji_new/emo0.png"
                          style="max-width: 32px; vertical-align: top"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Hey, this is your satisfaction metric result. It is automatically calculated based on your preferences and satisfaction levels with the questions in this category."
                          alt="Default emoji"
                        />
                      </ng-container>
                      <div class="submission question-submission">
                        <a href="javascript:void(0)" class="comment">
                          <ng-container
                            *ngIf="subCategory.comments === undefined"
                          >
                            <img
                              class="commentimg"
                              src="assets/imgs/comment.png"
                              style="
                                width: 36%;
                                margin-top: -30px;
                                margin-right: 23px !important;
                              "
                              alt="Comment"
                            />
                          </ng-container>
                          <!-- <ng-container *ngIf='subCategory.comments == ""'>
                                                          <img class="commentimg" src="assets/imgs/comment.png" style="width:36%;margin-top: -30px;margin-right:45px;">
                                                      </ng-container> -->
                          <ng-container
                            *ngIf="subCategory.comments !== undefined"
                          >
                            <ng-container *ngIf="subCategory.comments === ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/comment.png"
                                style="
                                  width: 36%;
                                  margin-top: -30px;
                                  margin-right: 23px !important;
                                "
                                alt="Comment"
                              />
                            </ng-container>
                            <ng-container *ngIf="subCategory.comments !== ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/chat.png"
                                style="
                                  width: 36%;
                                  margin-top: -30px;
                                  margin-right: 23px !important;
                                "
                                alt="Chat"
                              />
                            </ng-container>
                          </ng-container>

                          <!-- <img class="commentimg" src="assets/imgs/comment.png" style="width:36%;margin-top: -30px;margin-right:45px;"> -->

                          <div class="mbtextarea">
                            <ng-container *ngIf="surveyStatus === true">
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (keypress)="
                                  msgCommentsSubCategory($event, subCategory)
                                "
                                >{{ subCategory.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                            <ng-container *ngIf="surveyStatus === false">
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (blur)="saveComments1($event, subCategory)"
                                >{{ subCategory.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success save-comment"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <div class="spinner" *ngIf="isDisabled === true">
                    <mat-spinner color="#237ba0"></mat-spinner>
                  </div>
                  <div
                    class="row row-priorities subc"
                    *ngFor="let question of questions; let i = index"
                  >
                    <div class="form-group">
                      <label
                        class="col-md-5 col-xs-12 control-label slide-label"
                        >{{ question.content }}</label
                      >

                      <div class="submission submission2">
                        <div
                          class="no-normal"
                          style="float: left; padding-top: 8px; font-size: 17px"
                          *ngIf="isValues === true"
                        >
                          {{ question.nosValue }}
                        </div>
                        <!--- <ng-container
                          *ngIf="question.satisfactionLevel !== 'undefined'"
                        >
                          <ng-container
                            *ngIf="question.satisfactionLevel !== ''"
                          >
                            <mat-slider
                              min="0"
                              max="100"
                              thumbLabel
                              [disabled]="surveyStatus"
                              [(ngModel)]="question.satisfactionLevel"
                              (change)="saveSatisfactionLevel($event, question)"
                            ></mat-slider>
                          </ng-container>
                          <ng-container
                            *ngIf="question.satisfactionLevel === ''"
                          >
                            <mat-slider
                              min="0"
                              max="100"
                              thumbLabel
                              [disabled]="surveyStatus"
                              value="50"
                              (change)="saveSatisfactionLevel($event, question)"
                            ></mat-slider>
                            ///old comment start
                            -- Here2
                                                          <ng-container *ngIf='question.satisfactionLevel == 0'>
                                                                  Here3
                                                              <mat-slider min="0" max="100" thumbLabel disabled={{surveyStatus}} value="{{question.satisfactionLevel}}" (change)="saveSatisfactionLevel($event, question)"></mat-slider>
                                                          </ng-container>
                                                          <ng-container *ngIf='question.satisfactionLevel != 0'>
                                                                  Here4
                                                              <mat-slider min="0" max="100" thumbLabel disabled={{surveyStatus}} value="50" (change)="saveSatisfactionLevel($event, question)"></mat-slider>
                                                          </ng-container> ///old comment end
                          </ng-container>
                        </ng-container>
                        <ng-container
                          *ngIf="question.satisfactionLevel === 'undefined'"
                        >
                          <mat-slider
                            min="0"
                            max="100"
                            thumbLabel
                            [disabled]="surveyStatus"
                            value="50"
                            (change)="saveSatisfactionLevel($event, question)"
                          ></mat-slider>
                        </ng-container> -->

                        <ng-container>
                          <mat-slider
                            min="0"
                            max="100"
                            step="1"
                            thumbLabel
                            [disabled]="surveyStatus"
                          >
                            <input
                              matSliderThumb
                              name="question-slider-{{ question._id }}"
                              [(ngModel)]="question.satisfactionLevel"
                              (input)="
                                updateSubcategorySliderValue($event, question)
                              "
                            />
                          </mat-slider>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === ''">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo0'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo0"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo1'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo1"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="question.expressions === 'emo2'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo2"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo3'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo3"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo4'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo4"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo5'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo5"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo7'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo7"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo8'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo8"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo9'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo9"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <ng-container *ngIf="question.expressions === 'emo10'">
                          <a
                            class="FB_reactions {{ surveyStatus }}"
                            data-reactions-type="horizontal"
                            data-unique-id="1"
                            data-emoji-class="emo10"
                            style="
                              width: auto;
                              border: 0;
                              background-size: 32px;
                            "
                            href="javascript:void(0)"
                          >
                            <span style="font-size: 0; line-height: 0">{{
                              question.expressions
                            }}</span>
                          </a>
                        </ng-container>

                        <input
                          type="hidden"
                          class="reaction-btn"
                          value="{{ question.expressions }}"
                          (click)="saveReaction($event, question)"
                        />

                        <a href="javascript:void(0)" class="comment">
                          <ng-container *ngIf="question.comments === undefined">
                            <img
                              class="commentimg"
                              src="assets/imgs/comment.png"
                              style="width: 25%"
                              alt="Comment"
                            />
                          </ng-container>
                          <ng-container *ngIf="question.comments !== undefined">
                            <ng-container *ngIf="question.comments === ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/comment.png"
                                style="width: 25%"
                                alt="Comment"
                              />
                            </ng-container>
                            <ng-container *ngIf="question.comments !== ''">
                              <img
                                class="commentimg"
                                src="assets/imgs/chat.png"
                                style="width: 25%"
                                alt="Chat"
                              />
                            </ng-container>
                          </ng-container>

                          <!-- <img class="commentimg" src="assets/imgs/comment.png" style="width:25%;"> -->

                          <div>
                            <ng-container
                              *ngIf="surveyStatus === 'emojiDisabledClass'"
                            >
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (keypress)="msgComments($event, question)"
                                >{{ question.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                            <ng-container *ngIf="surveyStatus === false">
                              <textarea
                                placeholder="Your Comment"
                                rows="3"
                                class="comment-area"
                                (blur)="saveComments($event, question)"
                                >{{ question.comments }}</textarea
                              >
                              <button
                                type="button"
                                class="btn btn-success save-comment"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                class="btn btn-success close-comment"
                              >
                                X
                              </button>
                            </ng-container>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row row-about4">
                    <div class="form-group">
                      <div class="col-sm-6" align="left">
                        <div class="container-fluid">
                          <ul class="footer-nav1">
                            <li class="active">
                              <a routerLink="/dashboard">
                                <img
                                  src="assets/imgs/home.png"
                                  title="Dashboard"
                                  alt="Dashboard"
                                />
                              </a>
                            </li>
                            <li>
                              <!--                                                            <a routerLink="/about" style="padding-right:20px; border-right: 1px solid #a59090;">-->
                              <a routerLink="/about">
                                <img
                                  src="assets/imgs/user.png"
                                  title="Profile"
                                  alt="Profile"
                                />
                              </a>
                            </li>
                            <!--                                                        <li>
                                                                                                                      <a [routerLink]="['/survey', surveyId]" style="padding-right:10px; border-right: 1px solid #a59090;">
                                                                                                                          <img src="assets/imgs/category.png" title="Back to Main Categories">
                                                                                                                      </a>
                                                                                                                  </li>-->

                            <!-- <ng-container *ngFor="let category of categories">
                                                              <li>
                                                                  <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                      <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                                  </a>
                                                              </li>
                                                          </ng-container> -->
                            <!--                                                        <ng-container *ngFor="let category of categories">
                                                                                                                      <ng-container *ngFor="let newcategory of newcategories">
                                                                                                                          <ng-container *ngIf="category.categoryId == newcategory.categoryId">
                                                                                                                              <ng-container *ngIf="newcategory.status == 'completed'">
                                                                                                                                  <ng-container *ngIf="newcategory.title == 'Meaningful Work'">
                                                                                                                                      <li>
                                                                                                                                          <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                                                                              <img src="assets/imgs/newMeaningful Work.png" title="{{newcategory.title}}">
                                                                                                                                          </a>
                                                                                                                                      </li>
                                                                                                                                  </ng-container>
                                                          
                                                                                                                                  <ng-container *ngIf="newcategory.title == 'Compensation and Benefits'">
                                                                                                                                      <li>
                                                                                                                                          <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                                                                              <img src="assets/imgs/newCB.png" title="{{newcategory.title}}">
                                                                                                                                          </a>
                                                                                                                                      </li>
                                                          
                                                                                                                                  </ng-container>
                                                          
                                                                                                                                  <ng-container *ngIf="newcategory.title == 'Career Future'">
                                                                                                                                      <li>
                                                                                                                                          <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                                                                              <img src="assets/imgs/newCF.png" title="{{newcategory.title}}">
                                                                                                                                          </a>
                                                                                                                                      </li>
                                                          
                                                                                                                                  </ng-container>
                                                          
                                                                                                                                  <ng-container *ngIf="newcategory.title == 'Corporate Management'">
                                                                                                                                      <li>
                                                                                                                                          <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                                                                              <img src="assets/imgs/newCM.png" title="{{newcategory.title}}">
                                                                                                                                          </a>
                                                                                                                                      </li>
                                                          
                                                                                                                                  </ng-container>
                                                          
                                                                                                                                  <ng-container *ngIf="newcategory.title == 'Environment'">
                                                                                                                                      <li>
                                                                                                                                          <a [routerLink]="['/survey', surveyId, 'category', newcategory.categoryId]">
                                                                                                                                              <img src="assets/imgs/newenv.png" title="{{newcategory.title}}">
                                                                                                                                          </a>
                                                                                                                                      </li>
                                                                                                                                  </ng-container>
                                                                                                                              </ng-container>
                                                                                                                              <ng-container *ngIf="newcategory.status == 'incompleted'">
                                                                                                                                  <li>
                                                                                                                                      <a [routerLink]="['/survey', surveyId, 'category', category.categoryId]">
                                                                                                                                          <img src="assets/imgs/{{category.categoryIcon}}" title="{{category.title}}">
                                                                                                                                      </a>
                                                                                                                                  </li>
                                                                                                                              </ng-container>
                                                                                                                          </ng-container>
                                                                                                                      </ng-container>
                                                                                                                  </ng-container>-->
                          </ul>
                        </div>
                      </div>
                      <div class="col-sm-6" align="right">
                        <div
                          class="btn-group btn-group-lg"
                          role="group"
                          aria-label="Next Previous button group"
                        >
                          <button
                            type="button"
                            class="btn btn-default prev-btn"
                            (click)="moveToPreviousStep()"
                          >
                            &#171;&nbsp;Previous
                          </button>

                          <button
                            type="button"
                            class="btn btn-default next-btn"
                            (click)="moveToNextStep()"
                            [disabled]="nextCatId === '' ? true : false"
                          >
                            Next&nbsp;&#187;
                          </button>
                        </div>
                        <ng-container *ngIf="nextCatId == ''">
                          <ng-container *ngIf="surveyStatus == false">
                            <ng-container *ngIf="count == 5">
                              <button
                                type="button"
                                class="btn btn-sm btn-primary btn-about"
                                (click)="submitSurvey()"
                              >
                                DONE
                                <img
                                  style="max-width: 30px; margin-left: 3px"
                                  src="assets/imgs/submit.png"
                                />
                              </button>
                            </ng-container>
                            <ng-container *ngIf="count != 5">
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#submitModal"
                                class="btn btn-sm btn-primary btn-about"
                                style="opacity: 0.7"
                                >DONE
                                <img
                                  style="max-width: 30px; margin-left: 3px"
                                  src="assets/imgs/submit.png"
                                />
                              </a>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <!-- END panel-->
        </div>
      </div>
    </div>
    <!-- END row-->
  </section>

  <div class="modal fade" id="submitModal" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
          <h4 class="modal-title">Submit Survey</h4>
        </div>
        <div class="modal-body">
          <p>
            You have not yet answered all the questions in the survey.
            Submitting it now might provide incomplete or incorrect results.
            Would you like to submit the survey anyway?
          </p>
          <button
            type="button"
            class="btn btn-sm btn-primary btn-about"
            (click)="submitSurvey()"
          >
            SUBMIT NOW
            <img
              style="max-width: 30px; margin-left: 3px"
              src="assets/imgs/submit.png"
              alt="Submit icon"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
