import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SurveyService } from './../services/survey.service';
import { Survey } from './../types/survey';
import { UserService } from './../services/user.service';
import { Category } from './../types/category';
import { newCategory } from './../types/newcategory';
import { MatSnackBar } from '@angular/material/snack-bar';
import $ from 'jquery';
import { Question } from './../types/question';
import { OPTIONAL } from './../../config/constants';
declare var jQuery: any;

@Component({
  selector: 'survey-category',
  templateUrl: './survey-category.component.html',
  standalone: false,
})
export class SurveyCategoryComponent implements OnInit, AfterViewChecked {
  currentUserId: string | null;
  surveyId: string;
  userId: string | undefined;
  categories: Category[];
  newcategories: newCategory[];
  contentAvailable: boolean;
  questions: Question[] | undefined;
  categoryId: string;
  subCategoryId: string;
  widgetsInitialized: boolean;
  count: number;
  surveys: Survey[];
  surveyStatus: any;
  isValues: boolean;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.categories = [];
    this.surveys = [];
    this.newcategories = [];
    this.contentAvailable = false;
    this.categoryId = '';
    this.subCategoryId = '';
    this.widgetsInitialized = false;
    this.count = 0;
    this.surveyStatus = '';
    this.isValues = false;
  }

  public ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const surveyId = params.get('surveyId');
      if (surveyId) {
        this.surveyId = surveyId;
        this.contentAvailable = false;
        this.widgetsInitialized = false;
        this.getSettings();
        this.getSurveyCategories();
        this.getSurveyNewChangeCategories();
        this.getSurveyStatus();
        this.categories.forEach((category) => {
          category.satisfactionLevel = this.getInitialSliderValue(
            category.satisfactionLevel
          );
        });
      }
    });
  }

  updateSliderValue(event: Event, category: Category): void {
    const input = event.target as HTMLInputElement;
    const newValue = input.valueAsNumber;

    category.satisfactionLevel = newValue;

    // Now call your original logic
    this.setSatisfactionLevel({ value: newValue }, category);
  }

  getInitialSliderValue(value: any): number {
    if (value === '' || value === undefined || value === null) {
      return 50; // default slider value
    }
    return Number(value); // make sure it's a number
  }

  ngAfterViewChecked(): void {
    if (!this.widgetsInitialized) {
      if (jQuery('.submission .comment').length > 0) {
        console.log('initializing widget');
        this.widgetsInitialized = true;

        // Bind a handler to the "click" event of comment icon/image.
        $('.comment img').on('click', function () {
          console.log('click event of comment icon');
          $(this)
            .parent()
            .children('.comment div')
            .fadeIn(250, function () {
              $(this).children('.comment-area').focus();
            });
        });

        // Bind a handler to the "click" event of Submit/Close button in the comment box.
        $('.comment button.save-comment, .comment button.close-comment').on(
          'click',
          function () {
            $(this).parent().parent().children('.comment div').fadeOut(250);
          }
        );
      }
    }
  }

  getSettings(): void {
    this.surveyService.getSetting().subscribe({
      next: (data) => {
        if (data.data !== null) {
          if (data.data.showfrontvalue === '1') {
            this.isValues = true;
          } else {
            this.isValues = false;
          }
        } else {
          this.isValues = false;
        }
      },
      error: (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      },
    });
  }

  getSurveyCategories(): void {
    this.categories = [];

    this.surveyService.getCategories(this.surveyId).subscribe({
      next: (data) => {
        this.widgetsInitialized = false;

        data.categories.forEach((category: any) => {
          this.categories.push({
            userId: this.currentUserId ?? '',
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            categoryIcon: category.categoryIcon,
            title: category.title,
            satisfactionLevel: '',
            expressions: '',
            comments: category.comments,
          });
        });
        this.getSurveyCategoriesUserResponse();
      },
      error: (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      },
    });
  }

  getSurveyCategoriesUserResponse(): void {
    this.surveyService
      .getSurveyCategoriesUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: '',
      })
      .subscribe({
        next: (data) => {
          this.contentAvailable = true;
          if (data.categories.length > 0) {
            data.categories.forEach((category: any) => {
              this.categories.forEach((thisCategory) => {
                if (thisCategory.categoryId === category.categoryId) {
                  if (category.satisfactionLevel < OPTIONAL) {
                    setTimeout(function () {
                      $('#' + category.categoryId).css('opacity', '0.5');
                    }, 200);
                  } else {
                    setTimeout(function () {
                      $('#' + category.categoryId).css('opacity', '1');
                    }, 200);
                  }

                  thisCategory.satisfactionLevel = category.satisfactionLevel;
                  thisCategory.comments = category.comments;
                  thisCategory.expressions = category.expressions
                    ? category.expressions
                    : '';
                }
              });
            });
          }

          this.widgetsInitialized = false;
        },
        error: (error) => {
          this.contentAvailable = true;
          console.log('Failed');
          console.log(error);
        },
      });
  }

  submitSurvey(): void {
    this.surveyService
      .submitSurvey({
        userId: this.currentUserId,
        surveyId: this.surveyId,
      })
      .subscribe({
        next: (data) => {
          // Navigate to the dashboard.
          jQuery('#submitModalCat').modal('hide');
          this.router.navigate(['/dashboard']);
          // Show notification message (using Snackbar component).
          this.snackBar.open(
            'Thank you, your survey has been submitted, and we will share the results with you shortly.',
            '',
            {
              duration: 5000,
            }
          );
        },
        error: (error) => {
          console.log('Failed to update survey status.');
          console.log(error);
        },
      });
  }

  /**
   * Since this is the main categories listing page, on click of "Next" - it navigates to the first category page.
   */
  moveToNextStep(): void {
    let firstCategory = this.categories[0];
    this.router.navigate([
      '/survey',
      this.surveyId,
      'category',
      firstCategory.categoryId,
    ]);
  }

  setSatisfactionLevel(event: any, category: Category) {
    let satisfactionLevel: number = event.value;

    if (satisfactionLevel < OPTIONAL) {
      setTimeout(function () {
        $('#' + category.categoryId).css('opacity', '0.5');
      }, 200);
    } else {
      setTimeout(function () {
        $('#' + category.categoryId).css('opacity', '1');
      }, 200);
    }

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: '', // this is a root category
      categoryId: category.categoryId,
      satisfactionLevel: satisfactionLevel,
    });
  }

  saveComments(event: any, category: Category): void {
    // Get comments.
    let comments: string = event.target.value;

    // Hide the comment box.
    $(event.target).parent().fadeOut(250);

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: '', // this is a root category
      categoryId: category.categoryId,
      comments: comments,
    });
  }

  msgComments(event: any, category: Category): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  saveUserResponse(data: any): void {
    this.surveyService.saveSurveyCategoriesUserResponse(data).subscribe({
      next: (data) => {
        // Write a code to show some notification if required.
        this.widgetsInitialized = false;
        this.getSurveyCategoriesUserResponse();
      },
      error: (error) => {
        console.log('Failed to save user response.');
        console.log(error);
      },
    });
  }

  getSurveyNewChangeCategories(): void {
    this.newcategories = [];

    let data = 'userId=' + this.currentUserId + '&surveyId=' + this.surveyId;

    this.surveyService.getNewChangeCategories(data).subscribe({
      next: (data) => {
        this.widgetsInitialized = false;

        data.data.forEach((category: any) => {
          this.newcategories.push({
            userId: this.currentUserId ?? '',
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            title: category.title,
            status: category.status,
            satisfactionLevel: '',
            expressions: '',
          });

          if (category.status === 'completed') {
            this.count++;
          }
        });

        // this.getSurveyNewChangeCategoriesUserResponse();
      },
      error: (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      },
    });
  }

  getSurveyNewChangeCategoriesUserResponse(): void {
    this.surveyService
      .getNewChangeCategories({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        // "parentCategoryId": ""
      })
      .subscribe({
        next: (data) => {
          this.contentAvailable = true;
          if (data.categories && data.categories.length > 0) {
            data.categories.forEach((category: any) => {
              this.categories.forEach((thisCategory) => {
                if (thisCategory.categoryId === category.categoryId) {
                  thisCategory.satisfactionLevel = category.satisfactionLevel;
                  thisCategory.expressions = category.expressions
                    ? category.expressions
                    : '';
                }
              });
            });
          }
          this.widgetsInitialized = false;
        },
        error: (error) => {
          this.contentAvailable = true;
          console.log('Failed');
          console.log(error);
        },
      });
  }

  getSurveyStatus(): void {
    if (this.currentUserId) {
      this.surveyService.getSurveyStatus(this.currentUserId).subscribe({
        next: (data) => {
          if (data.results.length > 0) {
            data.results.forEach((survey: any) => {
              if (this.surveyId === survey.surveyId) {
                if (survey.status === 'COMPLETED') {
                  this.surveyStatus = true;
                } else {
                  this.surveyStatus = false;
                }
              }
            });
          }
        },
        error: (error) => {
          console.log('Failed to get user survey status data.');
          console.log(error);
        },
      });
    }
  }
}
