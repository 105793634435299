import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SurveyService } from './../services/survey.service';
import { UserService } from './../services/user.service';
import { Category } from './../types/category';
import { MatSnackBar } from '@angular/material/snack-bar';
import { newCategory } from './../types/newcategory';
import { Question } from './../types/question';
import { OPTIONAL } from './../../config/constants';
declare var $: any;

@Component({
  selector: 'survey-subcategory',
  templateUrl: './survey-subcategory.component.html',
  styleUrls: ['./survey-subcategory.component.css'],
  standalone: false,
})
export class SurveySubcategoryComponent implements OnInit, AfterViewChecked {
  currentUserId: string;
  surveyId: string;
  categoryId: string;
  subCategoryId: string;
  categoryTitle: string;
  categories: Category[];
  subCategories: Category[];
  mainCategory: Category[];
  contentAvailable: boolean;
  widgetsInitialized: boolean;
  questions: Question[] | undefined;
  newcategories: newCategory[];
  count: number;
  surveyStatus: any;
  isValues: boolean;

  constructor(
    private userService: UserService,
    private surveyService: SurveyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {
    this.currentUserId = this.userService.getCurrentUserId();
    this.surveyId = '';
    this.categoryId = '';
    this.subCategoryId = '';
    this.categoryTitle = '';
    this.categories = [];
    this.mainCategory = [];
    this.newcategories = [];
    this.contentAvailable = false;
    this.widgetsInitialized = false;
    this.count = 0;
    this.subCategories = [];
    this.surveyStatus = '';
    this.isValues = false;
  }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (
        params['surveyId'] !== undefined &&
        params['categoryId'] !== undefined
      ) {
        this.surveyId = params['surveyId'];
        this.categoryId = params['categoryId'];
        this.contentAvailable = false;

        this.getSettings();
        this.getCategory();
        this.getSurveyCategories();
        this.getSurveySubcategories();
        this.getSurveyNewChangeCategories();
        this.getSurveyStatus();
        this.getSurveyCategoriesUserResponse();
        this.getSurveyCategoryUserResponse();
        this.categories.forEach((subCategory) => {
          subCategory.satisfactionLevel = this.getInitialSliderValue(
            subCategory.satisfactionLevel
          );
        });
      }
    });
  }

  updateSubcategorySliderValue(event: Event, subCategory: any): void {
    const input = event.target as HTMLInputElement;
    const newValue = input.valueAsNumber;

    subCategory.satisfactionLevel = newValue;

    this.setSatisfactionLevel({ value: newValue }, subCategory);
  }

  getInitialSliderValue(value: any): number {
    if (value === '' || value === undefined || value === null) {
      return 50; // or whatever default you want
    }
    return Number(value);
  }

  ngAfterViewChecked(): void {
    if (!this.widgetsInitialized) {
      if (jQuery('.submission .comment').length > 0) {
        console.log('initializing widget in Sub Category');
        this.widgetsInitialized = true;

        // Bind a handler to the "click" event of comment icon/image.
        $('.comment img').on('click', () => {
          console.log('click event of comment icon in sub categoery');
          $(this)
            .parent()
            .children('.comment div')
            .fadeIn(250, () => {
              $(this).children('.comment-area').focus();
            });
        });

        // Bind a handler to the "click" event of Submit/Close button in the comment box.
        $('.comment button.save-comment, .comment button.close-comment').on(
          'click',
          () => {
            $(this).parent().parent().children('.comment div').fadeOut(250);
          }
        );
      }
    }
  }
  getSettings(): void {
    this.surveyService.getSetting().subscribe(
      (data) => {
        if (data.data !== null) {
          if (data.data.showfrontvalue == '1') {
            this.isValues = true;
          } else {
            this.isValues = false;
          }
        } else {
          this.isValues = false;
        }
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }
  getCategory(): void {
    this.surveyService.getCategory(this.categoryId).subscribe(
      (data) => {
        this.categoryTitle = data.category.title;
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getSurveyCategories(): void {
    this.categories = [];

    this.surveyService.getCategories(this.surveyId).subscribe(
      (data) => {
        this.widgetsInitialized = false;
        data.categories.forEach((category: any) => {
          this.categories.push({
            userId: this.currentUserId,
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            categoryIcon: category.categoryIcon,
            title: category.title,
            satisfactionLevel: '',
            expressions: '',
            comments: category.comments,
          });
          if (category.status == 'completed') {
            this.count++;
          }
        });
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getSurveySubcategories(): void {
    this.subCategories = [];

    this.surveyService
      .getSubcategories(this.surveyId, this.categoryId)
      .subscribe(
        (data) => {
          if (data.subcategories.length == 0) {
            this.router.navigate([
              '/survey',
              this.surveyId,
              'category',
              this.categoryId,
              'subcategory',
              'none',
            ]);
          } else {
            data.subcategories
              .filter((category: any) => !category.isDeleted)
              .forEach((category: any) => {
                this.subCategories.push({
                  userId: this.currentUserId,
                  surveyId: category.surveyId,
                  parentId: category.parentId,
                  categoryId: category._id,
                  description: category.description,
                  categoryIcon: '',
                  title: category.title,
                  satisfactionLevel: '',
                  expressions: '',
                  comments: category.comments,
                });
              });

            this.getSurveySubcategoriesUserResponse();
          }
        },
        (error) => {
          console.log('Failed to get sub-category data.');
          console.log(error);
        }
      );
  }

  getSurveyNewChangeCategories(): void {
    this.newcategories = [];

    let data = 'userId=' + this.currentUserId + '&surveyId=' + this.surveyId;

    this.surveyService.getNewChangeCategories(data).subscribe(
      (data) => {
        data.data.forEach((category: any) => {
          this.newcategories.push({
            userId: this.currentUserId,
            surveyId: category.surveyId,
            parentId: category.parentId,
            categoryId: category._id,
            description: category.description,
            title: category.title,
            status: category.status,
            satisfactionLevel: '',
            expressions: '',
          });

          if (category.status == 'completed') {
            this.count++;
          }
        });

        // this.getSurveyNewChangeCategoriesUserResponse();
      },
      (error) => {
        console.log('Failed to get category data.');
        console.log(error);
      }
    );
  }

  getSurveySubcategoriesUserResponse(): void {
    this.getSurveyCategoriesUserResponse();
    this.surveyService
      .getSurveyCategoriesUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: this.categoryId,
      })
      .subscribe(
        (data) => {
          this.contentAvailable = true;
          if (data.categories.length > 0) {
            data.categories.forEach((category: any) => {
              this.subCategories.forEach((thisSubcategory) => {
                if (thisSubcategory.categoryId == category.categoryId) {
                  thisSubcategory.satisfactionLevel =
                    category.satisfactionLevel;
                  thisSubcategory.comments = category.comments;
                  thisSubcategory.expressions = category.expressions
                    ? category.expressions
                    : '';
                }
              });
            });
          }
        },
        (error) => {
          this.contentAvailable = true;
          console.log('Failed');
          console.log(error);
        }
      );
  }

  submitSurvey(): void {
    this.surveyService
      .submitSurvey({
        userId: this.currentUserId,
        surveyId: this.surveyId,
      })
      .subscribe(
        (data) => {
          console.log('Survey status updated successfully.', data);
          $('#submitModalSubCat').modal('hide');
          // Navigate to the dashboard.
          this.router.navigate(['/dashboard']);
          // Show notification message (using Snackbar component).
          this.snackBar.open(
            'Thank you, your survey has been submitted, and we will share the results with you shortly.',
            '',
            {
              duration: 5000,
            }
          );
        },
        (error) => {
          console.log('Failed to update survey status.');
          console.log(error);
        }
      );
  }

  /**
   * This is the category page with sub-categories listing, on click of "Previous" - it navigates to the previous category page with sub-categories listing.
   */
  moveToPreviousStep(): void {
    this.surveyService
      .getPrevCategory({
        surveyId: this.surveyId,
        catId: this.categoryId,
        subCatId: '',
      })
      .subscribe(
        (data) => {
          if (data.catId == '' && data.prevCatId == '') {
            this.router.navigate(['/survey', this.surveyId]);
          } else {
            if (data.catId == '') {
              this.router.navigate([
                '/survey',
                this.surveyId,
                'category',
                data.prevCatId,
              ]);
            } else {
              this.router.navigate([
                '/survey',
                this.surveyId,
                'category',
                data.prevCatId,
                'subcategory',
                data.catId,
              ]);
            }
          }
        },
        (error) => {
          console.log('Failed to get previous category data.');
          console.log(error);
        }
      );
  }

  /**
   * This is the category page with sub-categories listing, on click of "Next" - it navigates to the first sub-category page with questions listing.
   */
  moveToNextStep(): void {
    let firstSubCategory = this.subCategories[0];
    this.router.navigate([
      '/survey',
      this.surveyId,
      'category',
      this.categoryId,
      'subcategory',
      firstSubCategory.categoryId,
    ]);
  }

  setSatisfactionLevel(event: any, subCategory: Category) {
    let satisfactionLevel: number = event.value;

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: subCategory.parentId, // this is a sub-category
      categoryId: subCategory.categoryId,
      satisfactionLevel: satisfactionLevel,
    });

    // this.surveyService
    //     .saveSurveyCategoriesUserResponse(
    //         {
    //             "userId": this.currentUserId,
    //             "surveyId": this.surveyId,
    //             "parentCategoryId": subCategory.parentId, // this is a sub-category
    //             "categoryId": subCategory.categoryId,
    //             "satisfactionLevel": satisfactionLevel
    //         }
    //     )
    //     .subscribe(
    //         data => {
    //             console.log("Success saving user sub-category response", data);
    //         },
    //         error => {
    //             console.log('Failed to save user sub-category response.');
    //             console.log(error);
    //         }
    //     );
  }

  saveComments(event: any, subCategory: Category): void {
    // Get comments.
    let comments: string = event.target.value;

    // Hide the comment box.
    $(event.target).parent().fadeOut(250);

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: subCategory.parentId, // this is a sub-category
      categoryId: subCategory.categoryId,
      comments: comments,
    });
  }

  msgComments(event: any, category: Category): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  saveUserResponse(data: any): void {
    //$(".prev-btn").attr("disabled", "true");
    //$(".next-btn").attr("disabled", "true");
    this.surveyService.saveSurveyCategoriesUserResponse(data).subscribe(
      (data) => {
        // Write a code to show some notification if required.
        // console.log("saveUserResponse success callback...");
        // console.log(data);
        this.widgetsInitialized = false;
        this.getSurveySubcategoriesUserResponse();
        //$(".prev-btn").removeAttr("disabled");
        //$(".next-btn").removeAttr("disabled");
      },
      (error) => {
        console.log('Failed to save user response.');
        console.log(error);
      }
    );
  }

  getSurveyStatus(): void {
    this.surveyService.getSurveyStatus(this.currentUserId).subscribe(
      (data) => {
        if (data.results.length > 0) {
          data.results.forEach((survey: any) => {
            if (this.surveyId == survey.surveyId) {
              if (survey.status == 'COMPLETED') {
                this.surveyStatus = true;
              } else {
                this.surveyStatus = false;
              }
            }
          });
          // if (data.results[0].status == "COMPLETED") {
          //     this.surveyStatus = true;
          // } else {
          //     this.surveyStatus = false;
          // }
        }
      },
      (error) => {
        console.log('Failed to get user survey status data.');
        console.log(error);
      }
    );
  }

  getSurveyCategoriesUserResponse(): void {
    this.surveyService
      .getSurveyCategoriesUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        parentCategoryId: '',
      })
      .subscribe(
        (data) => {
          this.contentAvailable = true;
          if (data.categories.length > 0) {
            // data.categories.forEach(category => {
            //     this.categories.forEach(thisCategory => {
            //         if (thisCategory.categoryId == category.categoryId) {
            //             thisCategory.satisfactionLevel = category.satisfactionLevel;
            //             thisCategory.comments = category.comments;
            //             thisCategory.expressions = category.expressions ? category.expressions : "";
            //         }
            //     });
            // });
            this.mainCategory = [];
            this.mainCategory.push({
              userId: this.currentUserId,
              surveyId: this.surveyId,
              parentId: '',
              categoryId: this.categoryId,
              description: '',
              categoryIcon: '',
              title: '',
              satisfactionLevel: '',
              expressions: '',
              comments: '',
            });
            data.categories.forEach((category: any) => {
              if (this.categoryId == category.categoryId) {
                this.mainCategory[0].satisfactionLevel =
                  category.satisfactionLevel;
                this.mainCategory[0].comments = category.comments;
                this.mainCategory[0].expressions = category.expressions
                  ? category.expressions
                  : '';
              }
            });
          }

          this.widgetsInitialized = false;
        },
        (error) => {
          this.contentAvailable = true;
          console.log('Failed');
          console.log(error);
        }
      );
  }

  saveCatComments(event: any, category: Category): void {
    // Get comments.
    let comments: string = event.target.value;

    // Hide the comment box.
    $(event.target).parent().fadeOut(250);

    this.saveUserResponse({
      userId: this.currentUserId,
      surveyId: this.surveyId,
      parentCategoryId: '', // this is a root category
      categoryId: this.categoryId,
      comments: comments,
    });
  }

  msgCatComments(event: any, category: Category): void {
    this.snackBar.open(
      'Survey already submitted, Data entered will not be saved.',
      '',
      {
        duration: 5000,
      }
    );
    $(event.target).parent().fadeOut(250);
  }

  saveCatUserResponse(data: any): void {
    //$(".prev-btn").attr("disabled", "true");
    //$(".next-btn").attr("disabled", "true");
    this.surveyService.saveSurveyCategoriesUserResponse(data).subscribe(
      (data) => {
        // Write a code to show some notification if required.
        // console.log("saveUserResponse success callback...");
        // console.log(data);
        this.widgetsInitialized = false;
        this.getSurveyCategoriesUserResponse();
        //$(".prev-btn").removeAttr("disabled");
        //$(".next-btn").removeAttr("disabled");
      },
      (error) => {
        console.log('Failed to save user response.');
        console.log(error);
      }
    );
  }

  getSurveyCategoryUserResponse(): void {
    this.surveyService
      .getSurveyCategoryUserResponse({
        userId: this.currentUserId,
        surveyId: this.surveyId,
        categoryId: this.categoryId,
      })
      .subscribe(
        (data) => {
          if (data.categories.length > 0) {
            if (data.categories[0].satisfactionLevel < OPTIONAL) {
              setTimeout(function () {
                $('.subc').css('opacity', '0.5');
              }, 200);
            } else {
              setTimeout(function () {
                $('.subc').css('opacity', '1');
              }, 200);
            }
          }
        },
        (error) => {
          console.log('Failed');
          console.log(error);
        }
      );
  }
}
